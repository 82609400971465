import React from 'react'
import { Provider } from 'react-redux'
import HomePage from '../Components/HomePage.js'
import CustomSnackbar from '../Components/Utilities/CustomSnackbar.js'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

const ReactRouter = ({ store }) => (
  <Provider store={store}>
    <CustomSnackbar />
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/project/:project" component={HomePage} />
        <Route exact path="/speech/:speech" component={HomePage} />
        <Route> 
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  </Provider>
)

export default ReactRouter