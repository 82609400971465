// import Marquee from 'react-smooth-marquee'
import { colorArray } from '../consts'
import { BsPlus, BsDash } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'
import ProjectActions from '../../Actions/ProjectActions'
import React, { useState, useEffect, useRef } from 'react'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'

const SideBar = () => {

  const { isHeb, sort, lineLength } = useSelector(state => state.project)
  const [scroll, setScroll] = useState(window.pageYOffset)
  const dispatch = useDispatch()
  const mounted = useRef(false)

  const body = document.body,
    html = document.documentElement;

  const height = Math.max(body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight);

  const totalHeight = height
  const totalScroll = totalHeight - window.innerHeight

  function handleScrollChange() {
    setScroll(window.pageYOffset)
  }

  useEffect(() => {
    mounted.current = true;
    mounted.current && window.addEventListener('scroll', handleScrollChange)

    return () => { 
      mounted.current = false
      window.removeEventListener('scroll', handleScrollChange)
    }
  }, [])

  function handleChangeLang(value) {
    if (isHeb === value) {
      return
    }

    localStorage.setItem('isHeb', JSON.stringify(!isHeb))
    dispatch(ProjectActions.toggleHeb())
  }

  function renderLangButtons() {
    const langStyle = {
      fontSize: '16px',
      textAlign: 'center',
      float: 'right'
    }

    return (
      <div style={{float: 'right', paddingTop: '10px'}}>
        <p
          onClick={() => handleChangeLang(true)}
          style={{
            ...langStyle,
            marginRight: '35px',
            marginLeft: 0,
            cursor: isHeb ? 'default' : 'pointer',
            fontWeight: isHeb ? 'bold' : 'lighter'
          }}
        >
          עב
          </p>
        <p style={{ ...langStyle, marginRight: '4px', marginLeft: '4px' }}>|</p>
        <p
          onClick={() => handleChangeLang(false)}
          style={{ ...langStyle, fontWeight: isHeb ? 'lighter' : 'bold', cursor: isHeb ? 'pointer' : 'default' }}
        >
          En
          </p>
      </div>
    )
  }

  function handleIncreaseZoom() {
    const { zoomIn } = ProjectActions
    dispatch(zoomIn())
  }

  function handleReduceZoom() {
    const { zoomOut } = ProjectActions
    dispatch(zoomOut())
  }

  function renderButtons() {
    const className = isMobile ? 'mobileZoomButton' : 'zoomButton'
    const style = isMobile ? { borderRight: 'inset', borderColor: '#00000029', borderWidth: '1px' } : { borderBottom: 'inset', borderColor: '#00000029', borderWidth: '1px'}
    const boxStyle = isMobile ? {float: 'left', height: '34px', position: 'relative', top: '-8px', width: '100%'} : {width: '100%'}
    const zoomSymbol = isMobile ? {} : {position: 'relative', top: -5}
    return (
      <div style={boxStyle}>
        <button style={style} onClick={handleIncreaseZoom} className={className}><BsPlus style={zoomSymbol}/></button>
        <button style={style} onClick={handleReduceZoom} className={className}><BsDash style={zoomSymbol}/></button>
        {isMobile && 
        <label 
          style={{ marginLeft: '5px', fontSize: '18px', color: '#4D4D4D', fontWeight: 'lighter', paddingLeft: '20px'}}
        >
          {100 + (12.5 * (5 - lineLength))}%
        </label>
        }
        {isMobile && renderLangButtons()}
        <div className='clear' />
      </div>
    )
  }

  function renderDepGradScroll() {
    const yValue = scroll === 0 || totalScroll === 0 ? '0%' : `${(scroll / totalScroll) * 100}%`
    return (
      <div style={{height: 'calc(100% - 150px)'}}>
        <svg style={{position: 'relative', left: '40%', top: '2.5%'}} height='95%' width='100%'>
          <defs>
            <linearGradient id='grad1' x1='0%' y1='0%' x2='0%' y2='100%'>
              <stop offset={`0%`} stopColor={'#4D4D4D'} stopOpacity='1' />
              <stop offset={`100%`} stopColor={'#E3E3E3'} stopOpacity='1' />
            </linearGradient>
          </defs>
          <rect height='100%' width='10' fill='url(#grad1)' />
          <text className='gradIndicator' fontWeight='bold' rotate='90' fill="#FFFFFF" y={yValue}>|</text>
        </svg>
      </div>
    )
  }

  function renderGradScroll() {
    const { index } = sort
    let firstPart = colorArray.slice(index, colorArray.length)
    let secondPart = colorArray.slice(0, index)
    const tempColors = [...firstPart, ...secondPart]
    const yValue = scroll === 0 || totalScroll === 0 ? '0%' : `${(scroll / totalScroll) * 95}%`

    return (
      <div style={{height: 'calc(100% - 150px)'}}>
        <svg style={{ position: 'relative', left: '40%', top: '2.5%'}} height='95%' width='100%'>
          <defs>
            <linearGradient id='grad1' x1='0%' y1='0%' x2='0%' y2='100%'>
              {tempColors.map((color, index) => <stop key={`stop${color}`} offset={`${5 * index}%`} stopColor={color} stopOpacity='1' />)}
            </linearGradient>
          </defs>
          <rect height='100%' width='10' fill='url(#grad1)' />
          <text className='gradIndicator' fontWeight='bold' rotate='90' fill="#FFFFFF" y={yValue}>|</text>
        </svg>
      </div>
    )
  }

  function renderMobileGradScroll() {
    const { type, index } = sort
    let tempColors = [...colorArray]

    if (type !== 'dep') {
      let firstPart = colorArray.slice(index, colorArray.length)
      let secondPart = colorArray.slice(0, index)
      tempColors = [...firstPart, ...secondPart]
    }

    return (
      <div style={{position: 'relative', top: '-5px' }}>
        <svg height='15px' width='100%'>
          <defs>
            <linearGradient id='grad1' x1='0%' y1='0%' x2={isMobile ? '100%' : '0%'} y2={isMobile ? '0%' : '100%'}>
              {tempColors.map((color, index) => <stop key={`stop${color}`} offset={`${5 * index}%`} stopColor={color} stopOpacity='1' />)}
            </linearGradient>
          </defs>
          <rect height='15px' width='100%' fill='url(#grad1)' />
        </svg>
      </div>
    )
  }

  return (
    <div>
      <BrowserView>
        <div style={{ width: '50px' }} className={isHeb ? 'hebSidebar' : 'hebSidebar'}>
          {renderButtons()}
          {/* <hr /> */}
          {sort.type === 'dep' ? renderDepGradScroll() : renderGradScroll()}
        </div>
      </BrowserView>

      <MobileView>
        <div className='mobileSidebar'>
          {renderMobileGradScroll()}
          {renderButtons()}
          <div className='clear'/>
        </div>
      </MobileView>
    </div>
  )
}

export default SideBar
