import {
  ZOOM_IN,
  ZOOM_OUT,
  SET_SORT,
  INC_LOADED,
  SET_SCROLL,
  TOGGLE_HEB,
  SET_FILTER,
  ADD_PROJECTS,
  SET_SIDE_NAME,
  HOVERED_PROJECT
} from '../ActionTypes/ProjectActionTypes'

const toggleHeb = () => ({ type: TOGGLE_HEB })
const setSort = sort => ({ type: SET_SORT, data: sort })
const incLoaded = () => ({ type: INC_LOADED })
const addProjects = projects => ({ type: ADD_PROJECTS, data: projects })
const filterProjects = projects => ({ type: SET_FILTER, data: projects })

const selectProject = project => ({ type: HOVERED_PROJECT, data: project })
const setSideName = name => ({ type: SET_SIDE_NAME, data: name })
const zoomIn = () => ({ type: ZOOM_IN })
const zoomOut = () => ({ type: ZOOM_OUT })
const setScroll = scroll => ({ type: SET_SCROLL, data: scroll })

export default {
  zoomIn,
  zoomOut,
  setSort,
  incLoaded,
  setScroll,
  toggleHeb,
  setSideName,
  addProjects,
  selectProject,
  filterProjects
}