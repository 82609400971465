import { colorArray } from '../consts'
import { isMobile } from 'react-device-detect'
import React, { useState, useEffect, useRef } from 'react'

const Loading = props => {
  const { setLoaded, projectShown } = props
  const [colors, setColors] = useState(colorArray)
  const [hasLoaded, setHasLoaded] = useState(false)
  const time = useRef(undefined)
  const currIndex = useRef(0)

  function handleLoaded(fromInterval) {
    window.removeEventListener('load', () => handleLoaded(false))
    if(fromInterval) {
      setLoaded(true)
      setHasLoaded(true)
    }
    else {
      const tempTime = new Date().getTime()
      if(tempTime > time.current + 4000) {
        setLoaded(true)
        setHasLoaded(true)
      }
      else {
        const interval = setInterval(() => {
          setLoaded(true)
          setHasLoaded(true)
          clearInterval(interval)
        }, (time.current + 4000) - tempTime)
      }
    }
  }

  // useEffect(() => {
  //   let ttl = localStorage.getItem('projectsTtl') ? JSON.parse(localStorage.getItem('projectsTtl')) : 0
  //   let now = new Date().getTime()
  //   const tempLoaded = localStorage.getItem('loaded') ? JSON.parse(localStorage.getItem('loaded')) : false
  //   if(!tempLoaded || now > ttl) {
  //     let interval = setInterval(() => {
  //       setLoaded(true)
  //       setHasLoaded(true)
  //       clearInterval(interval)
  //       localStorage.setItem('loaded', 'true')
  //     }, 10000)
  //   }
  //   else {
  //     let interval = setInterval(() => {
  //       setLoaded(true)
  //       setHasLoaded(true)
  //       clearInterval(interval)
  //     }, 2000)
  //   }
  // }, [])

  useEffect(() => {
    window.addEventListener('load', () => handleLoaded(false))
    time.current = new Date().getTime()
    // let colorInterval = setInterval(() => {
    //   let firstPart = colorArray.slice(currIndex.current, colorArray.length)
    //   let secondPart = colorArray.slice(0, currIndex.current)
    //   let tempColors = [...firstPart, ...secondPart]
    //   setColors(tempColors)
    //   currIndex.current = currIndex.current + 1 >= colorArray.length ? 0 : currIndex.current + 1
    // }, 150)

    let interval = setInterval(() => {
      handleLoaded(true)
      clearInterval(interval)
      // clearInterval(colorInterval)
    }, 10000)

  }, [])

  // function renderTitle() {
  //   const containerStyle = {
  //     width: '100%',
  //     position: 'relative',
  //     left: `calc(100vw * 0.037 - 10px)`,
  //   }

  //   const textStyle = {
  //     fontSize: '3.6vw',
  //     color: '#E5E5E5',
  //     fontWeight: 'bold'
  //   }

  //   return (
  //     <div style={{width: '100%'}}>
  //       <div style={containerStyle}>
  //         <label style={{...textStyle, marginRight: '3.5%'}}>2020</label>
  //         <label style={{...textStyle, marginRight: '3.6%'}}>Shankar Graduation</label>
  //         <label style={{...textStyle, marginRight: '3.5%'}} className='arabicFont'>نهاية السنة شنكار</label>
  //         <label style={{...textStyle, marginRight: '3.5%'}}>סופשנה שנקר</label>
  //       </div>
  //     </div>
  //   )
  // }

  function renderTitle() {
    const style = {
      width: '84vw',
      height: 'auto',
      position: 'relative',
      left: '8vw'
    }

    return (
      <img
        alt='lineLogo'
        style={style}
        src={require('../../Images/line logo.png')}
      />
    )
  }

  function renderGradScroll() {
    return (
      <div style={{ position: 'absolute', top: projectShown ? '-6px' : '-6px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height='15px' width='100vw'>
          <defs>
            <linearGradient id='gradLoading' x1='0%' y1='0%' x2='100%' y2='0%'>
              {colors.map((color, index) => {
                let firstPart = colorArray.slice(index + 1, colorArray.length)
                let secondPart = colorArray.slice(0, index)
                let tempColors = [...firstPart, ...secondPart]
                let colorValues = ''
                tempColors.map((color, tempIndex) => {
                  if(index === tempIndex) {
                    return null
                  }

                  colorValues += color +';'
                  return null
                })
                return (
                  <stop key={`stop${color}`} offset={`${5 * index}%`} stopColor={color} stopOpacity='1'>
                    <animate attributeName="stop-color" values={colorValues} dur="2.0s" repeatCount="indefinite"/>
                  </stop>
                )
              }
              )}
            </linearGradient>
          </defs>
          <rect height='15px' width='100%' fill='url(#gradLoading)' />
        </svg>
      </div>
    )
  }


  const style = {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    zIndex: 2,
    top: 0,
    backgroundColor: '#FFFFFF'
  }

  const gifStyle = {
    width: isMobile ? '180px' : '200px',
    height: 'auto',
    position: 'absolute',
    left: '50%',
    top: isMobile ? '38%' : '50%',
    transform: isMobile ? 'translate(-50%, -38%)' : 'translate(-50%, -50%)'
  }

  const containerStyle = isMobile ? 
    { marginTopLeft: '15%', marginTop: '15px'} 
    : 
    { marginTop: '3%', width: '100%' }

  return (
    <div className={hasLoaded ? 'slide-top' : ''} style={style}>
      {renderGradScroll()}
      <div style={containerStyle}>
        {!isMobile && renderTitle()}
      </div>
      <img alt='loading' style={gifStyle} src={require('../../Images/loading.gif')} />
      <p className='loading'>Loading...</p>
    </div>
  )
}

export default Loading