import {
  ZOOM_IN,
  SET_SORT,
  ZOOM_OUT,
  INC_LOADED,
  SET_SCROLL,
  SET_FILTER,
  TOGGLE_HEB,
  ADD_PROJECTS,
  SET_SIDE_NAME,
  HOVERED_PROJECT
} from '../ActionTypes/ProjectActionTypes'
import { isMobile } from "react-device-detect"
import projectList from '../Components/projects.json'

const initialState = {
  projects: projectList,
  filteredProjects: projectList,
  projectsLoaded: true,
  selectedProject: undefined,
  isHeb: localStorage.getItem("isHeb") ? JSON.parse(localStorage.getItem("isHeb")) : true,
  sort: { type: 'topic', index: 0 },
  lineLength: isMobile ? 5 : 13,
  sideName: 'עיצוב אופנה',
  scroll: 0,
  hasLoaded: false
}

const sortProjects = (a, b) => {
  let nameA = a.projectName.toUpperCase()
  let nameB = b.projectName.toUpperCase()

  if (nameA < nameB) {
    return -1
  }

  if (nameA > nameB) {
    return 1
  }

  return 0
}

export default (state = initialState, action) => {
  switch (action.type) {

    case SET_FILTER : {
      return {
        ...state,
        filteredProjects: action.data
      }
    }

    case ADD_PROJECTS: {
      const newList = action.data
      
      newList.sort(sortProjects)

      return {
        ...state,
        projects: newList,
        projectsLoaded: true
      }
    }

    case HOVERED_PROJECT: {
      return {
        ...state,
        selectedProject: action.data
      }
    }

    case SET_SORT : {
      return {
        ...state,
        sort: action.data
      }
    }

    case SET_SCROLL: {
      return {
        ...state,
        scroll: action.data
      }
    }

    case TOGGLE_HEB : {
      return {
        ...state,
        isHeb: !state.isHeb
      }
    }

    case ZOOM_IN : {
      if(!isMobile && state.lineLength === 5) {
        return state
      }

      if(isMobile && state.lineLength === 3) {
        return state
      }

      return {
        ...state,
        lineLength: state.lineLength - 2
      }
    }

    case ZOOM_OUT : {
      if(!isMobile && state.lineLength === 25) {
        return state
      }

      if (isMobile && state.lineLength === 13) {
        return state
      }

      return {
        ...state,
        lineLength: state.lineLength + 2
      }
    }

    case SET_SIDE_NAME : {
      return {
        ...state,
        sideName: action.data
      }
    }

    case INC_LOADED : {
      return {
        ...state,
        loadedPhotos: state.loadedPhotos + 1
      }

    }

    default:
      return state
  }
}