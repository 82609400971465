import { MDBBtn } from 'mdbreact'
import HamburgerMenu from 'react-hamburger-menu'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ProjectActions from '../../Actions/ProjectActions'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import { 
  headerTitle, 
  hebHeaderTitle,
  hebDepButtonText,
  hebTopicButtonText,
  depButtonText,
  topicButtonText,
  colorArray
} from '../consts'
import MobileOverlay from './MobileOverlay'

const Header = props => {

  const { sendBack, projectShown } = props
  const dispatch = useDispatch() 
  const { toggleHeb } = ProjectActions
  const [toggleMenu, setMenu] = useState(false)
  const [menuType, setType] = useState(undefined)
  const { isHeb, sort, projects } = useSelector(state => state.project) 
  const { setOverlay, overlay } = props
  const [logoStyle] = useState({
    marginTop: '11px',
    marginLeft: '9px',
    float: 'left',
    height: '27px',
    width: '32px',
    cursor: 'pointer'
  })

  function handleChangeLang(value) {
    if(isHeb === value) {
      return
    }

    localStorage.setItem("isHeb", JSON.stringify(!isHeb))
    dispatch(toggleHeb())
  }

  function handleSendBack() {
    dispatch(ProjectActions.setScroll(0))
    dispatch(ProjectActions.filterProjects(projects))
    sendBack()
  }

  function renderButtons() {
    const depButton = isHeb ? hebDepButtonText : depButtonText
    const topicButton = isHeb ? hebTopicButtonText : topicButtonText
    const style = isHeb ? { float: 'right' } : { float: 'right' }
    const buttonStyle = isHeb ? 
    { fontWeight: '200', float: 'right', width: '120px', height: '50px' } 
    : 
    { fontWeight: '200', float: 'right', width: '160px', height: '50px' }
    const depClass = `selectButton ${overlay === 'dep' ? 'selectedButton' : ''}`
    const topicClass = `selectButton ${overlay === 'topic' ? 'selectedButton' : ''}`
    const langStyle = {
      fontSize: '16px',
      textAlign: 'center',
      paddingTop: '13px',
      height: '100%',
      float: isHeb ? 'right' : 'right',
    }
    
    const searchButtonStyle = {
      fontWeight: '200', 
      float: 'right', 
      width: '48px',
      height: '50px',
      backgroundColor: '#434343'
    }

    return (
      <div style={style}>
        <MDBBtn
          onClick={() => overlay === 'topic' ? setOverlay(undefined) : setOverlay('topic')} 
          color="elegant" 
          className={topicClass} 
          style={buttonStyle}
        >
          {topicButton}
        </MDBBtn>

        <MDBBtn
          onClick={() => overlay === 'dep' ? setOverlay(undefined) : setOverlay('dep')}
          color="elegant"
          className={depClass}
          style={buttonStyle}
        >
          {depButton}
        </MDBBtn>

        <MDBBtn
          onClick={() => overlay === 'search' ? setOverlay(undefined) : setOverlay('search')}
          color="elegant"
          className='searchButton'
          style={searchButtonStyle}
        >
          <img alt='search' src={require('../../Images/search-solid.svg')} />
        </MDBBtn>
        <p 
          onClick={() => handleChangeLang(true)} 
          style={{...langStyle, 
            marginRight: '35px', 
            // marginLeft: isHeb ? 0 : '35px', 
            cursor: isHeb ? 'default' : 'pointer',
            fontWeight: isHeb ? 'bold' : 'lighter'
          }}
        >
          עב
        </p>
        <p style={{...langStyle, marginRight: '4px', marginLeft: '4px'}}>|</p>
        <p 
          onClick={() => handleChangeLang(false)} 
          style={{...langStyle, fontWeight: isHeb ? 'lighter' : 'bold', cursor: isHeb ? 'pointer' : 'default'}}
        >
          En
        </p>

      </div>
    )
  }

  function handleMenuClick() {
    const { setOverlay } = props

    if(menuType !== undefined && toggleMenu) {
      setMenu(false)
      setType(undefined)
      setOverlay(undefined)
    }

    else {
      setOverlay(undefined)
      setMenu(!toggleMenu) 
    }
  }

  function renderHamburger() {

    const searchButtonStyle = {
      fontWeight: '200',
      float: 'right',
      position: 'relative',
      top: '-18px',
      right: '14px'
    }

    const style = {
      float: isHeb ? 'right' : 'right',
      position: 'relative',
      top: '-12px',
      marginRight: isHeb ? '18px' : '18px',
      marginLeft: isHeb ? 0 : 0
    }

    return (
      <div>
        <div style={style}>
          <HamburgerMenu
            isOpen={toggleMenu && overlay !== 'search'}
            menuClicked={handleMenuClick}
            width={18}
            height={13}
            strokeWidth={2}
            color='#FFFFFF'
            borderRadius={0}
            animationDuration={0.5}
          />
        </div>  
        <button
          onClick={() => overlay === 'search' ? setOverlay(undefined) : setOverlay('search')}
          className='invisButton'
          style={searchButtonStyle}
        >
          <img alt='searchButton' src={overlay === 'search' ? require('../../Images/xSearchMobile.svg') : require('../../Images/search-solid.svg')} />
        </button>
      </div>  
    )
  }

  function renderTitle() {
    const title = isHeb ? hebHeaderTitle : headerTitle
    let style = {}
    if(isMobile) {
      style = isHeb ?
        { width: '50%',fontSize: '17px', position: 'relative', left: '20%', transform: 'translate(-50%)', top: '15px' } 
        : 
        { width: '60%', fontSize: '17px', position: 'relative', left: '24%', transform: 'translate(-50%)', top: '15px' } 
    }
    else {
      style = isHeb ?
        { cursor: 'pointer', position: 'relative', fontSize: '17px', left: '12%', top: '15px' }
        :
        { cursor: 'pointer', fontSize: '17px', position: 'relative', left: '16%', top: '15px' }
    }

    return (
      <div>
        <label onClick={handleSendBack} style={style}><b>{title}</b></label>
      </div>
    )
  }

  function handleMobileClick(value) {
    const { setOverlay } = props
    setType(value)
    setOverlay('mobile')
  }

  function renderMenuButtons() {
    const topicButton = isHeb ? hebTopicButtonText : topicButtonText
    const depButton = isHeb ? hebDepButtonText : depButtonText

    const style = {
      width: '100vw',
      height: '70px',
      backgroundColor: '#4D4D4D',
      color: '#FFFFFF',
      fontSize: '18px',
      fontWeight: 'lighter',
      boxShadow: '0px 1px 6px #00000029'
    }

    return (
      <div style={{position: 'relative', top: '9px'}}>
        {projectShown && renderMobileGradScroll()}
        <button onClick={() => handleMobileClick('topic')} style={{ ...style, backgroundColor: '#4D4D4D' }} className='invisButton'>{topicButton}</button>
        <button onClick={() => handleMobileClick('dep')} style={{ ...style, backgroundColor: '#4D4D4D' }} className='invisButton'>{depButton}</button>
      </div>
    )
  }

  function renderMobileGradScroll() {
    const { index } = sort
    let firstPart = colorArray.slice(index, colorArray.length)
    let secondPart = colorArray.slice(0, index)
    const tempColors = [...firstPart, ...secondPart]

    return (
      <div style={{height: '15px'}}>
        <svg height='15px' width='100%' 
          style={{ position: 'relative', top: projectShown ? '-21px' : '-21px'}}
        >
          <defs>
            <linearGradient id='grad1' x1='0%' y1='0%' x2={isMobile ? '100%' : '0%'} y2={isMobile ? '0%' : '100%'}>
              {tempColors.map((color, index) => <stop key={`stop${color}`} offset={`${5 * index}%`} stopColor={color} stopOpacity='1' />)}
            </linearGradient>
          </defs>
          <rect height='15px' width='100%' fill='url(#grad1)' />
        </svg>
      </div>
    )
  }

  function renderMenu() {
    return menuType === undefined ? 
      renderMenuButtons() 
      : 
      (
        <div style={{ position: 'relative', top: '10px', width: '100%', height: '100%'}}>
          {projectShown && renderMobileGradScroll()}
          <MobileOverlay 
            projectShown={projectShown}
            type={menuType} 
            onClose={handleMenuClick}
            sendBack={handleSendBack}
          />
        </div>
      )
  }

  return (
    <div className="header">
      <header className="header">
        <img onClick={handleSendBack} style={logoStyle} src={require('../../Images/logo.png')} alt="Shenkar" id="logo" />
        <BrowserView>
          {renderButtons()}
          {renderTitle()}
        </BrowserView>
        <MobileView>
          {renderTitle()}
          {renderHamburger()}
          {toggleMenu && overlay !== 'search' && renderMenu()}
        </MobileView>
      </header>
    </div>
  )
}

export default Header
