import { hebrewDep } from './consts'
export const Speeches = new Map()
/* עיצוב אופנה */
Speeches[hebrewDep[0]] = {
  head: "אילן בז'ה",
  text: [
    `בימים אלה מסיימים 43 סטודנטים ארבע שנים של מסע אקדמי ויצירתי במיוחד, בשנה סוערת ומטלטלת שהעולם לא ידע ולא הכיר. `, 
    `מלאים בסקרנות, אופטימיות זהירה והיכרות עמוקה עם עולם העיצוב, האמנות והתרבות יוצאים הבוגרים אל דרכם העצמאית מצוידים בארגז עשיר בכלים עיצוביים, טכנולוגיים ומסחריים.`, 
    `שנת הלימודים האחרונה תפסה את הבוגרים עסוקים בשאלות רבות המשקפות את הלכי הרוח של הדור הצעיר, זאת לנוכח התמורות האדירות בעולם בכלל ובעולם האופנה בפרט. `,
    `תהליך עיצוב הקולקציות שהתחיל טרום תקופת קורונה עודד ביטוי אישי, מקורי ויצירתי, במהלכו נסוך בו רגישות ומודעות לתקופת הסגר ובסופו התמודדנו עם שאלות בוערות לגבי ההווה והעתיד אל מול השינויים הדרמטיים שמתרחשים בעולם. אף על פי זאת, הסטודנטים יצרו, עיצבו, ציירו, פיתחו, תפרו, גזרו, הדפיסו, רקמו, צבעו, ערכו וסגננו קולקציות מהן עולה אמירה אופנתית ייחודית ועזה, רלוונטית מתמיד.`,
    `I AM HUMAN AFTER ALL`,
    `חיפוש חיים חדשים, יצירת מתאר חדש לגוף האדם על ידי שימוש באלמנטים מפתיעים, מעבר בין עולם טבעי למלאכותי, הכמיהה להגיע חלל, נוודות מודרנית, יצירת מציאות חדשה על ידי בדיקת גזרות, בדים, סריגים, וטכניקות טכנולוגיות מודרניות – כל אלה מאפיינים את הקולקציות בקבוצה הזו. 
השטחים הפתוחים מול גוף האדם, הצבעוניות הארצית מול החללית, הנוודות הטכנולוגית החדשה והמצב ההיסטורי של האנושות היום, מובילים לחוויית מחקר ויזואלי חדשה היוצרת צלליות חדשות ומורכבות ובודקת את המרחב האישי והכה רלוונטי לימינו.
בנוסף, הקולקציה המציגה שיתוף פעולה חלוצי בשנקר, בין תחומי עיצוב והנדסה, בודקת את ההשפעה הישירה של הטכנולוגיה על התקשורת הבין אישית ויחסים בין בני אדם. האם ניתן ליצור אינטראקציות חדשות דרך הבגדים, זאת תו כדי שימוש בטכנולוגיה, מוסיקה, קצב ותנועה? 
`,
    `I AM HOME`,
    `קולקציות הלוקחות השראה מהמרחב הפיזי, התרבותי והשורשי בו הסטודנטים חיים, זאת דרך נקודת מבט מקומית ומודרנית. הן עוסקות במשפחה הגרעינית והמורחבת, שואלות שאלות בנוגע  לזהות מקומית ומקום מחייה אל מול המקורות הגלותיים, תרות אחר שפה חדשה בעקבות ההבדלים התרבותיים של גלי העלייה ומציגות עימות חדש ותרגום חזותי עכשווי ומיוחד.
סוגיות אלו מתחדדות במיוחד לאור התקופה האחרונה בה היינו סגורים פיזית וחברתית. הן הובילו לעיסוק במשפחה, בבית ובזיכרונות הילדות, במתח שבין האישי לציבורי בחייהם של הסטודנטים ובקשר שלהם עם הוריהם. 
`,
    `I AN NOT TRASH`,
    `הקולקציות בנושא זה מהדהדות בעיצוב שמגיב לאירועים החברתיים והכלכליים, שדן בסוגיית זיהום הסביבה, שמעלה שאלות על הצורך לצרוך אופנה מהירה, כל זאת דרך מחקר תיאורטי מעמיק לצד מחקר מעשי. 
הקולקציות מתעדת את המתרחש בנופים העירוניים והטבעיים בהם אנו חיים ומתרגמת את התיעוד הזה לטקסטילים וגזרות יוצאות דופן היוצרים אמירה אופנתית ומודרנית. 
הסטודנטים, אשר יוצרים ופועלים תחת חוקי הקיימות, עוצרים לרגע תוהים מה עתידה של האופנה. הם מקווים לפעול בסביבה מקיימת, סובלנית וסקרנית, שתעניק חיים חדשים לפסולת, בין השאר שקיות, תחפושות, אריזות ועודפי בדים.
`,
    `I AM OTHER`,
    `קבוצת קולקציות המדגישה אינדיבידואליות עיצובית: כזו שאינה מחויבת לקבוצת השתייכות ותוכן מגדרי אך מחויבת לבדוק תוכן שאינו כבול למגדר או חשיבה מסורתית. הסטודנטים אינם כורכים בגד ומגדר זה בזה ואינם מצפים לחלוקה חברתית ברורה - גם ביחס לעצמם וגם ביחס לסביבה ולחברה הם חיים.
הקולקציות אף מעלות שאלות בנוגע לייצוגים הדיגיטליים המתייחסים לזמניות המקום בו אנו חיים:? מהו אידיאל היופי בחדש? מיהו או מהו האחר? איפה מתחילה המציאות הפרטית ומסתיימת בתעתוע ציבורי ואובדן הפרטיות?
`,
    `I AM JOURNRY`,
    `קולקציות שהן מיקס בין ילדות לבגרות, בין תל אביב לערד, בין דמויות היסטוריות וספרותיות, גיבורי תרבות מובילים ומשפיעניות עכשוויות ששוברות את הרשת, בין הלחץ והרדיפה היומיומית לתרגול מיינדפולנס. 
תחילתו של המסע האישי בהשראה ויזואלית וציורית, עם כוונה אמיתית לשלב בצורה היברידית בין עבר ועתיד, בין חלומות בהקיץ למסע בתוך השינה, בין מקומי לבינלאומי, בין מסורת והיסטוריה תרבותית לחוויות אישיות המעסיקות את דור ה .z
`,
    `שלכם,`,
    `אילן בז'ה`,
    `ראש המחלקה לעיצוב אופנה, שנקר.`
  ]
}

/* עיצוב תעשייתי */
Speeches[hebrewDep[1]] = {
  head: '',
  text: []
}

/* תקשורת חזותית */
Speeches[hebrewDep[2]] = {
  head: '',
  text: []
}

/* עיצוב טקסטיל */
Speeches[hebrewDep[3]] = {
  head: 'הדס הימלשטיין',
  text: [
    `בוגרות יקרות, המחלקה לעיצוב טקסטיל….איזו שנה זו היתה!`,
    `השנה, התמודדתן, עם אתגרים שלא נראו בעבר.
אם בכל שנה אנחנו מחכים לראות מה המסר שתביאו ותעבירו לשנים הבאות וכיצד ייראה העתיד דרך העיניים שלכן…השנה, בעולם שאחרי הקורונה או בחיים שלצד הקורונה, שאלות אלה מקבלות משמעות חדשה כשברור לכולנו שמתוך המגבלות הנוכחיות צומח גם משהו חדש.
`,
    `פרויקט הגמר שהתחיל בהתרגשות גדולה ובימים של שגרה, נעצר ברגע אחד! ונכנס רגע לאחר מכן אל תוך מציאות חדשה שאילצה אתכם להגיב בנחישות ולהמציא המשך חדש וסיום לפרויקט הגמר.`,
    `העבודה מתוך הבית הפרטי, חדר השינה, המרפסות והחצרות שימשה עבורכן, הבוגרות, בסיס לפיתוח, לשדרוג ולשכלול של כל המיומנויות הידניות המוכרות לרמה חדשה. 
במקביל ומתוך הקשבה למציאות, הואצה עבודתכן בפלטפורמות הדיגיטליות במקביל לשמירה עיקשת על הקשר עם מעבדות הטקסטיל במחלקה ועבודה בקפסולות זעירות, לשמירת מרחק וכללים והנחיות שלא היו קיימים עד כה במרחב החיים של כולנו. כל אלה, הניבו פרקטיקות מרתקות ופתחו דרך לעשייה מקורית בעיצוב.
`,
    `הטקסטיל, שנמצא בכל מקום, מוכר בייחוד בשל נוכחותו הפיזית. אותה פיזיות וחומריות, נאלצה לחשב את עצמה מחדש. 
והשאלות המתייחסות לחומר שמזוהה כל כך עם הטקסטיל, הופכות בעת הזאת להיות מהותיות וחשובות מתמיד: 
כיצד מייצרים חומר כשאין גישה לטכנולוגיה?
מה עושים כשהחומרים לא זמינים?  
איך מייצרים חומר בעבודה מרחוק?
`,
  `בסופו של התהליך אנחנו עומדים ליד פרויקטים שעוסקים בסוגיות משגרת החיים: שאלות באסטטיקה וקולקציה, במהות החומר, במציאות אוטופית ודיסוטופית, ביחס הטקסטיל לגוף ולחלל, באיכות החיים ובריאות הגוף והנפש, באקולוגיה ובעיצוב של מוצרים רכים.`,
  `אני רוצה לברך אתכן בוגרות המחלקה על ההסתגלות המהירה למרות אי הוודאות והמשאבים המוגבלים, על תוצרים מרשימים, ועל מפגן של מומחיות בטקסטיל.
  
תודה מיוחדת לכל המרצים והעובדים במחלקה על המחויבות, הנתינה  והתגובה המהירה למצב החדש, על היחס האישי למרות המרחק והסגר בימים ושעות לא רגילים, תודה על שהייתם כאן ועכשיו, עבור הסטודנטים ועבור המחלקה של כולנו.
`,
    `המורכבות הנפלאה של הטקסטיל מזמנת לעוסקים בו מנעד רחב של אפשרויות. עברנו שנה שלא דומה לשום דבר…. וזאת הזדמנות לצמיחה מחודשת שלנו כמעצבים וכמרצים ושל הטקסטיל כאן בארץ.`
  ]
}

/* עיצוב תכשיטים */
Speeches[hebrewDep[4]] = {
  head: '',
  text: []
}

/* עיצוב פנים מבנה וסביבה */
Speeches[hebrewDep[5]] = {
  head: 'יהושע גוטמן',
  text: [
    `מסע הסטודנט במחלקה לעיצוב פנים וסביבה הינו מסע אישי, בו סטודנטים מגלים את עצמם, את קישוריהם, כישרונם, ואת מערכת היחסים שלהם עם סביבתם הקרובה והרחוקה.`,
    `השנים הראשונות (שנה א' וב') מוקדשות לרכישת כלים, טכניים, טכנולוגיים וחשיבתיים, בהשם ישתמשו הסטודנטים בהמשך הדרך ובעזרתם יוכלו להתמודד עם המורכבויות השונות של סביבות ומרחבי חיינו ושל הסביבה הבנויה והטבעית.`,
    `בשנים הגבוהות (שנים ג' וד') עוברים הסטודנטים מסע חוויתי בין מסלולים ומגמות שונות בתוך המקצוע.`,
    `במחלקה כיום ארבעה מסלולים העוסקים בנושאים הבאים:`,
    `•סביבה ואקולוגיה`,
    `•חללים פנימיים`,
    `•עיר העתיד`,
    `•בניה וחברה`,
    `מגוון הפרויקטים כולל בין השאר שאלות על חללי פנים תצוגה בזמן הקורונה, על השינוי בחללי הבית והעבודה; וכן פיתוח אלמנטים פנימיים וסביבתיים עם חומרים אורגאניים ועוד. `
  ]
}

/* התואר השני בעיצוב - כיתת המהנדסים */
Speeches[hebrewDep[6]] = {
  head: "רכזת כיתת מהנדסים גב' מירב פרץ וראש תואר שני פרופ' יעל מוריה",
  text: [
    `במסגרת אירועי הסיום בשנקר, יציגו מהנדסים שמסיימים בימים אלה את לימודיהם בתואר השני בעיצוב בשנקר. הסטודנטים מגיעים לאחר לימודי תואר ראשון בהנדסה והם בעלי ניסיון מקצועי בתחומם – הנדסת תוכנה, אלקטרוניקה, הנדסת חומרים, הנדסת מכונות ועוד. במהלך ארבעה סמסטרים רוכשים הסטודנטים הבנה בתהליכי עיצוב ושליטה בכלים שונים של מחקר, המחשה, מידול ופרזנטציה. `,
    `הפרויקטים מציגים חיבורים שונים בין ידע טכנולוגי, עיצוב עכשווי ושאלות חברתיות-תרבותיות הנכונות לזמן הזה. הסמסטר האחרון, אשר במהלכו עברו הלימודים למתכונת מקוונת בשל התפרצות וירוס הקורונה, זימן אתגרים חדשים ושינה את מסלולם של חלק מהפרויקטים. חלקם עברו מיקוד ושינוי, אחרים קיבלו משנה תוקף לאור האירועים האחרונים.`
  ]
}

/* תואר שני בעיצוב */
Speeches[hebrewDep[7]] = {
  head: '',
  text: []
}

/* בית-הספר לאמנות רב-תחומית */
Speeches[hebrewDep[8]] = {
  head: '',
  text: []
}

/* הנדסת חשמל ואלקטרוניקה */
Speeches[hebrewDep[9]] = {
  head: '',
  text: []
}

/* הנדסת חומרים פולימרים */
Speeches[hebrewDep[10]] = {
  head: "פרופ' עמוס אופיר",
  text: [
    `הצגת תוצרי מחקר הגמר ב-מסגרת התואר ראשון, ומחקר התזה ב-תואר השני, מהווים את גולת הכותרת של סיום מסלול הלימודים בכל אחת מהתכניות של המח' להנדסת חומרים פולימרים.`,
    `המדע והטכנולוגיה המשיקים לעולם החומרים הפולימרים, הם בחזית החדשנות והקדמה העולמית עתירת הידע, ובכל תחום גשמי-יישומי משמעותי, הסובב את חיינו בעת הזו.`,
    `החומרים הפולימרים הם פלטפורמת בסיס שבאמצעותה מופעלות המערכות המתקדמות והחכמות ביותר, אשר משרתות את העולם והאנושות, מתחומי הרפואה והתרופות, דרך החקלאות, המזון וטכנולוגיות המים והאקולוגיה,  ועד האלקטרו-אופטיקה, המיקרו-אלקטרוניקה ועולמות התחבורה, התעופה והחלל.`,
    `הלימודים המתקדמים ועבודות המחקר שנעשות במחלקה להנדסת חומרים פולימרים, הם/ן ברובם/ן בעלי/ות הכוונה יישומית-תעשייתית, ועל כן מהווים אתגר מאוד גדול ובר עניין לסטודנטים/יות שלמדו 4 שנים בתואר הראשון ו- 2-3 שנים בתואר השני, אשר בא לידי ביטוי בתוצרים היוצאים מן כלל של עבודות המחקר 'המרתקות' המוצגות כאן, ופתוחות לצפייה ועיון של הקהל הרחב. `,
    `ברכת יישר כוח והצלחה לסטודנטים/יות המסיימים/ות.`,
    `פרופ' עמוס אופיר – ראש המח' להנדסת חומרים פולימרים`
  ]
}

/* תואר שני בהנדסת חומרים פולימרים */
Speeches[hebrewDep[11]] = {
  head: '',
  text: []
}

/* הנדסת תוכנה */
Speeches[hebrewDep[12]] = {
  head: 'ד"ר יונית רושו',
  text: [
    `בוגרים ובוגרות יקרים,`,
    `4 שנות לימוד מסתיימות אחרי לילות לבנים רבים ומאמץ אדיר להשיג את המטרה- סיום תואר
    בהנדסת תוכנה.`,
    `המחלקה להנדסת תוכנה בשנקר מאופיינת במצוינות אקדמית, פדגוגית, מחקרית ויישומית.
    מצוינות זו באה לידי ביטוי בכל קורס שלמדתם והשקעתם בו, תהליך מחקרי בשנתכם האחרונה,
    בכל פרויקט יישומי שפיתחתם ודיונים מקצועיים שקיימתם.`,
    `המחצית האחרונה שלכם בלימודי התואר, בעבודה על פרויקטי הגמר, התקיימה בצל משבר
    הקורונה העולמי. בהתמדה וברצינות המשכתם לפעול למען סיום התואר. גיליתם אחריות
    ורצינות, ובצורה בוגרת הגעתם לקו הסיום. בתוך אי ודאות הוכחתם יכולות של מהנדסי תוכנה.
    לימודי הנדסת תוכנה הינם קשים ומאתגרים, ודורשים נחישות, התמדה, דבקות במטרה ולמידה
    עצמאית. צוות ההוראה של המחלקה להנדסת תוכנה בשנקר ליווה אתכם לאורך ארבע שנים
    ברגעי הצלחה וגם ברגעי משבר.`,
    `למחלקה להנדסת תוכנה בשנקר יש ייחודיות שלא קיימת במוסדות אחרים, יש איכויות
    להתגאות, להתפאר ולהציג. בתוך הפלטפורמה הזו התפתחם, צמחתם, התבגרתם,
    וכיום ניצבים לפנינו בוגרים ובוגרות מצוינים, מקצועיים, רציניים, המוכנים להתמודד עם העולם
    שמחכה לכם בחוץ.`,
    `רגע לפני הסיום, זכרו, הייתם למחזור נהדר ותמיד נהיה כאן עבורכם.`,
    `שימרו על עצמכם, היו השגרירים של המחלקה, המהווה לנצח בית שני של כולנו, שתהיה לכם
    דרך ישרה ובטוחה,`,
    `מאחלים לכם שפע ברכה והצלחה.`,
    `ד"ר יונית רושו וסגל המחלקה להנדסת תוכנה.`,
    `חיכיתי לרגע הזה שיגיע`,
    `וריק לי עכשיו משום מה בנשמה`,
    `צפוי כמו שמש`,
    `ובכל זאת מפתיע`,
    `העולם מאיים לי אבל גם מבטיח`,
    `זה היה זה נגמר`,
    `אבל יש הרגשה`,
    `שהסוף הוא תמיד, התחלה חדשה`,
    `התחלה חדשה / הפרוייקט של רביבו`
  ]
}

/* תעשייה וניהול */
Speeches[hebrewDep[13]] = {
  head: `פרופ' אהוד מניפז`,
  text: [
    `בשם בית הספר להנדסת תעשיה וניהול ובשמי אני מברך את כל המחזור המסיים של הסטודנטיות והסטודנטים
בבית הספר להנדסת תעשיה וניהול בברכת "יגעת ומצאת-תאמין!" שנת "הקורונה", שנת תש"פ, הציבה, ועדיין
מציבה, אתגר מורכב שבו אתם, סטודנטיות וסטודנטים יקרים, לצד כל הסגל האקדמי, סגל המתאמות והנהלת בית
הספר מהווים חלק מהמעבר המורכב מלמידה פרונטלית ללמידה מקוונת. ובהצלחה!`,
`התצוגה הדיגיטלית של מספר מצומצם של פרויקטים של סטודנטים המסיימים את לימודיהם בשנת הלימודים תש"פ
מאפשרת הצצה מרתקת לתחום ולתתי התחומים של הנדסת תעשיה וניהול. התצוגה הזו מעניינת במיוחד משלוש
סיבות: ראשית, בחינה של כל אחד מהפרויקטים המוצגים מבהירה כי מהנדס תעשייה וניהול הוא מהנדס בעל
ראייה מערכתית, המשתמש בעקרונות מדעיים וכלים טכנולוגיים והנדסיים לייזום, לתיכון, לתכנון, ארגון, יישום,
תפעול ובקרה של מערכות וארגונים להשגת יעדים עסקיים, באפקטיביות וביעילות, תוך שילוב היבטים תרבותיים
ואנושיים.`,
`שנית, בחינה של כל אחד מהפרויקטים המוצגים מביאה לידי ביטוי מעשי ומוחשי את תתי התחומים של הנדסת
תעשיה וניהול, כולל: אופטימיזציה של שרשראות אספקה (אלביט) ושל תהליכי שירות (חיל האוויר, מכוני טיפוח),
הקמה וניהול מיזמים במוסדות אקדמיים (שבוע המרקחה בשנקר), שימוש באלגוריתמים של למידת מכונה ובינה
מלאכותית (תעשיית (AdTech , שיפור תהליכים תוך שימוש בוויזואליזציה, חשיבה עיצובית והטמעה שיטתית של
מערכות מידע (חברת גרינגראס בע"מ לייצור גלי הינע). שלישית, בחינה של כל אחד מהפרויקטים מצביעה על
יישום הגישות של הנדסת תעשיה וניהול במרבית הוורטיקליים, המגזרים הכלכליים, המיוצגים בכלכלת מדינת
ישראל, כולל התעשייה הביטחונית, מפעלים תעשייתיים, ארגוני שירות, מכוני טיפוח יופי ואסתטיקה, מוסדות
אקדמיים.`,
`בית הספר להנדסת תעשיה וניהול, עם מעל לאלף סטודנטיות וסטודנטים, פועל בהתמדה למימוש החזון, שהוצג
בדצמבר 2016, למועצה האקדמית בשנקר: "להיות בית הספר המועדף להנדסת תעשיה וניהול אזורית וארצית".
בית הספר מציע תואר ראשון (B.Sc.) עם התמחויות בנושאי עיצוב וניהול מערכות דיגיטליות, עיצוב וניהול מערכות
שירות ותפעול ומכלול חדשנות ויזמות (מכלול ח"י). בנוסף, בית הספר להנדסת תעשיה וניהול מציע תואר שני
בהנדסת תעשיה וניהול (M.Sc.) עם דגש על חדשנות דיגיטלית וארגונית, לצד תכנית ערב, תכנית המשלבת קריירה
ולימודים (תכנית קו"ל)ומכינה לתואר שני, תכנית בל"ש . בית הספר מעורב ונותן חסות לשלושת הפעילויות הלאומיות
בתחום הנדסת תעשיה וניהול בארץ: ההקאתון הלאומי להנדסת תעשיה וניהול (בשנת 2020 , בעטיו של משבר
הקורונה, נדחה ההאקתון הלאומי ה-4 לשנת 20201), הכינוס הלאומי הדו שנתי ה-22 להנדסת תעשיה וניהול
(אמור להתקיים ב- 20 אפריל 2021) והכינוס הדו שנתי הלאומי ה-9 למחקר בהנדסת תעשיה וניהול (התקיים ב-
4 מרץ 2020 בטכניון-מט"ל). בכל מסלולי הלימוד השונים, כ-20% מהקורסים בתכנית הלימודים בהנדסת תעשיה
וניהול מועברים בצורה מקוונת. בתכנית הלימודים ניתן דגש ללמידה מבוססת פרויקטים, להכשרה בטכנולוגיות
דיגיטליות, כגון למידת מכונה, למידה עמוקה, BIG DATA. כמו כן, ניתן דגש להתמחויות בתעשייה וקורסים בניהול,
שיווק, הנדסה פיננסית, חדשנות ויזמות. הנהלת בית הספר שוקדת על עדכון תכנית הלימודים תוך השוואה ולמידה
מתכניות הלימודים באוניברסיטאות המחקר ובמכללות. לבית הספר להנדסת תעשיה וניהול ועדת היגוי המסייעת
בעדכון תכניות הלימודים והמורכבת מבכירים מהתעשייה ומקהילת העסקים ומבכירי אקדמיה בנושאים הרלוונטיים.
במקביל להוראה בקורסים, חברי הסגל האקדמי בבית הספר להנדסת תעשיה וניהול עוסקים במחקר לאומי ובינלאומי
בליבת המקצוע ובשירות לקהילה המקצועית והאזרחית.`,
`בכל סוף שנה אקדמית, אנו נפגשים באירוע חגיגי של סיום השנה האקדמית בבית הספר להנדסת תעשיה וניהול,
בשם "אירוע סוף!". באירוע סוף השנה האקדמית אנו אוכלים ושותים משהו טוב (לרוב בירה ג'מס), חוגגים הצלחות
של סטודנטים מצטיינים, מעניקים את פרסי ההאקתון הלאומי השנתי להנדסת תעשיה וניהול, מעניקים פרסים
למנחים מצטיינים של יזמים צעירים ישראל ומעניקים תעודות הוקרה למרצות ולמרצים של בית הספר להנדסת
תעשיה וניהול ולבכירי המהנדסים והמנהלים בארץ. "שנת הקורונה" מונעת קיום "אירוע סוף!" השנה, וזאת על
מנת לשמור על בריאות כולנו. תצוגה דיגיטלית זו של מספר מאד מצומצם של פרויקטים נבחרים מהווה חגיגה
מסוימת של הישגים אקדמיים. לסיום, ברצוני לברך כל אחת ואחד מכם על סיום השנה האקדמית תש"פ וסיום
הלימודים, ברצוני לברך את הסגל האקדמי והאדמיניסטרטיבי של בית הספר להנדסת תעשיה וניהול לרגל סיום
השנה, ברצוני להודות למרכז הפרויקטים של שנה ד', ד"ר יגאל דוד ואת גב' אליס מנדל מלשכת הדיקן על ניהול
מוצלח של המערכת של פרויקטים שנה ד' בעזרת עשרות מנחים אקדמיים למאות הסטודנטים בשנה ד'. ברצוני אף
לברך את מ"מ סגנית הדיקן, מיכל קורן ואת ראשי ההתמחויות, ד"ר עודד קורן, ד"ר עידו מורג, ד"ר מאירה לוי על סיום
שנת לימודים מאתגרת זו.`,
`בשם הסגל המחויב של בית הספר ברצוני לאחל מקרב לב הצלחה לבוגרות ולבוגרים בחיי העבודה ובחיים האישיים.
נתראה בלימודי התואר השני בהנדסת תעשיה וניהול בבית הספר להנדסת תעשיה וניהול בשנקר.`
  ]
}

/* הנדסה כימית */
Speeches[hebrewDep[14]] = {
  head: `דר' יואב רוקמן`,
  text: [
    `בוגרי תש"פ יקרים,`,
    `תקופה לא קלה עברה על כולנו בסמסטר האחרון בצל מחלת הקורונה;
    תקופה של לימוד מרחוק, חוסר וודאות, קשיים אישיים של כל אחד ואחת מכם. `,
    `קל וחומר ההתמודדות עם פרוייקט הגמר, שהצריך במרבית המקרים עבודה פיזית מקרוב הן במחלקה והן בחברות בהן הועסקתם
    ובשל מגבלות הקורונה, עבודות אלו נמנעו, נדחו או צומצמו מאוד. `,
    `אתם עמדתם במשימה בגבורה יוצאת דופן, ולמרות הקשיים, הסגר, והריחוק הצלחתם להגיע לתוצאות מרשימות ולסכם את עבודתכם בצורה נאותה וראויה ביותר. `,
    `על כך אני מברך אתכם ומבקש להודות לכם על המאמץ שהשקעתם. `,
    `אני גם מבקש להודות למנחים שלכם, מחוץ ומבפנים לשנקר על ההירתמות להנחות בתנאים קיצוניים ולהביא לסיום פרק זה.`,
    `אתם יוצאים עוד מעט לדרך ארוכה, לבניית הקריירה המקצועית שלכם, עם כלים שרכשתם במהלך התואר. מניסיון, אני יודע שכלים אלה יעזרו לכם להתמודד עם מגוון מאוד רחב של עבודות ואתגרים שתיתקלו בהם בהמשך. `,
    `אני מקווה שתשמרו איתנו על קשר, תעדכנו אותנו בהצלחותיכם ותיעזרו בנו כשאתם זקוקים לכך.`,
    `חברי הסגל, עובדי המחלקה ואנכי מאחלים לכולכם המון הצלחה,`,
    `וכן, גם נתגעגע אליכם.... 😊`,
    `בברכה`,
    `דר' יואב רוקמן`,
    `ראש המחלקה להנדסה כימית`,
  ]
}
