import React from 'react'
import { MDBIcon } from 'mdbreact'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'

const Footer = () => {
  const { isHeb } = useSelector(state => state.project)
  const align = isHeb ? { textAlign: 'right' } : { textAlign: 'right' }
  const dir = isHeb ? 'rtl' : 'rtl'

  function renderDetails() {
    const designText = isHeb ? 'עיצוב האתר' : 'Designed by'
    const designName = isHeb ? 'סטודיו רוני ורוני' : 'Studio Roniverony'
    const developText = isHeb ? 'פיתוח: ' : 'Developed By: '
    const devNames = isHeb ? ['עידן עזרן', 'ויובל עוזרי'] : ['Edan Azran', '& Yuval Ozeri']
    const devEmails = ['edanazran@gmail.com', 'yuuval@gmail.con']
    const shenkarText = isHeb ? 'שנקר - הנדסה. עיצוב. אומנות.' : 'Shenkar - Engineering. Design. Art.'
    const addressText = isHeb ? "אנה פרנק 12 רמת גן | ז'בוטינסקי 8 רמת גן" : 'Anna Frank 12 Ramat-Gan | Jabotinsky 8 Ramat-Gan'
    const contactText = isHeb ? 'טל. 036110000 | shenkar.ac.il' : 'Tel: 03-6110000 | shenkar.ac.il'
    const icons = isHeb ? ['instagram', 'facebook-f'] : ['instagram', 'facebook-f']
    const links = isHeb ? 
      ['https://www.instagram.com/shenkar_college/', 'https://www.facebook.com/Shenkar.College']
    :
      ['https://www.instagram.com/shenkar_college/', 'https://www.facebook.com/Shenkar.College']

    const textStyle = {
      ...align,
      fontSize: '14px',
      padding: 0,
      marign: 0,
      lineHeight: 0.3,
      fontWeight: 200,
      letterSpacing: '0.28px',
    }

    const iconStyle = {
      float: 'left',
      marginRight: '8px',
    }

    const hebIconStyle = {
      float: 'right',
      marginLeft: '8px',
    }

    return (
      <div style={{ marginTop: '55px' }}>
        <p dir={dir} style={textStyle}>{designText}: <a href='https://roniverony.com/' target='_blank' rel='noopener noreferrer'>{designName}</a></p>
        <p dir={dir} style={textStyle}>{developText}
          <a href={`mailto:${devEmails[0]}`}>{devNames[0]} </a>
          <a href={`mailto:${devEmails[1]}`}>{devNames[1]}</a>
        </p>
        <div style={{ marginTop: '25px' }}>
          <p dir={dir} style={textStyle}>{shenkarText}</p>
          <p dir={dir} style={textStyle}>{addressText}</p>
          <p dir={dir} style={textStyle}>{contactText}</p>
        </div>
        <div>
          <a 
            style={isHeb ? hebIconStyle : hebIconStyle} 
            href={links[0]}
            target='_blank'
            rel='noopener noreferrer'
          >
            <MDBIcon fab icon={icons[0]} />
          </a>
          <a 
            style={{ float: isHeb ? 'right' : 'right', marginRight: isHeb ? 0 :0 }}
            href={links[1]}
            target='_blank'
            rel='noopener noreferrer'
          >
            <MDBIcon fab icon={icons[1]} />
          </a>
        </div>
      </div>
    )
  }

  function renderLogo() {
    return (
      <div>
        <img
          style={{ float: isHeb ? 'left' : 'left', width: '37px', height: '30px', marginTop: isMobile ? '67px' : '20px' }}
          className='footerSymbol'
          src={require('../../Images/logo2.png')}
          alt='logo'
        />
        { isMobile && 
          <p
          style={{ float: isHeb ? 'right' : 'right', fontSize: '350%', fontWeight: 'bold', textAlign: 'center', marginTop: '47px' }}
          >
            2020
          </p>
        }
      </div>
    )
  }

  function renderFirst() {
    return (
      <div className={isHeb ? 'hebFooterBox' : 'hebFooterBox'} >
        <div>
          {renderLogo()}
          <p 
            dir={dir} 
            style={{ ...align, float: isHeb ? 'right' : 'right', fontWeight: 'bold', fontSize: '350%' }}
          >
            2020
          </p>
        </div>
        <div className='clear' />
        {renderDetails()}
      </div>
    )
  }

  function renderSecond() {
    const hebStyle = {
      left: '9.3%'
    }

    const style = {
      right: '9.55%'
    }


    return (
      <div className={isHeb ? 'hebFooterBox' : 'footerBox'} style={isHeb ? hebStyle : hebStyle}>
        <p style={{width: '100%', fontSize: '350%', fontWeight: 'bold', textAlign: 'center'}}></p>
      </div>
    )
  }

  function renderThird() {
    const hebStyle = {
      left: '25%',
      marginTop: '1.7%',
      height: '90%'  
    }

    const style = {
      right: '10%',
      marginTop: '1.7%',
      height: '90%'
    }

    return (
      <div className={isHeb ? 'hebFooterBox' : 'footerBox'} style={isHeb ? hebStyle : hebStyle}>
        <img alt='Exhibition Poster' src={require('../../Images/Exhibition.png')} />
      </div>
    )
  }

  const style = {
    paddingTop: '80px', 
    paddingLeft: isHeb ? '88px' : '88px',
    paddingRight: isHeb ? 0 : 0, 
    width: '100%'
  }

  function renderBrowser() {
    return (
      <div style={style}>
        {renderFirst()}
        {renderSecond()}
        {renderThird()}
        <div className='clear' />
      </div>
    )
  }

  function renderMobile() {
    return (
      <div className='container' style={{width: '90%'}}>
        {renderLogo()}
        <img 
          style={{float: isHeb ? 'right' : 'right', marginTop: '30px'}} 
          alt='Exhibition Poster' 
          src={require('../../Images/Exhibition.png')}
        />
        <div className='clear' />
        <div style={{marginTop: '50px'}}>
          <div>
            {/* <p
              dir={dir}
              style={{ ...align, fontWeight: 'bold', fontSize: '350%' }}
            >
              16.8-7.8
          </p> */}
          </div>
          {renderDetails()}
        </div>
      </div>
    )
  }

  return (
    <footer style={{minHeight: isMobile ? '770px' : '440px'}} className="page-footer font-small special-color-dark">
      {isMobile ? renderMobile() : renderBrowser()}
    </footer>
  )
}

export default Footer
