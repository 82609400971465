// import { Waypoint } from 'react-waypoint'
import { Speeches } from '../Speeches'
import { withRouter } from 'react-router'
// import openSocket from 'socket.io-client'
// import projectData from '../projects.json'
import { animateScroll } from 'react-scroll'
import { isMobile } from 'react-device-detect'
import MaskPicture from '../Utilities/MaskPicture'
import { useSelector, useDispatch } from 'react-redux'
import ProjectActions from '../../Actions/ProjectActions'
import React, { useEffect, useState, useRef } from "react"
import { SOCK_HOST, SOCK_GET_PROJECTS, englishDep, colors, tagsArray, additionalTags, englishTags } from '../consts'

// const socket = openSocket(SOCK_HOST)

const hasScrollbar = () => {
  // The Modern solution
  if (typeof window.innerWidth === 'number')
    return window.innerWidth > document.documentElement.clientWidth

  // rootElem for quirksmode
  let rootElem = document.documentElement || document.body

  // Check overflow style property on body for fauxscrollbars
  let overflowStyle

  if (typeof rootElem.currentStyle !== 'undefined')
    overflowStyle = rootElem.currentStyle.overflow

  overflowStyle = overflowStyle || window.getComputedStyle(rootElem, '').overflow

  // Also need to check the Y axis overflow
  let overflowYStyle

  if (typeof rootElem.currentStyle !== 'undefined')
    overflowYStyle = rootElem.currentStyle.overflowY

  overflowYStyle = overflowYStyle || window.getComputedStyle(rootElem, '').overflowY

  let contentOverflows = rootElem.scrollHeight > rootElem.clientHeight
  let overflowShown = /^(visible|auto)$/.test(overflowStyle) || /^(visible|auto)$/.test(overflowYStyle)
  let alwaysShowScroll = overflowStyle === 'scroll' || overflowYStyle === 'scroll'

  return (contentOverflows && overflowShown) || (alwaysShowScroll)
}

function getScrollbarWidth() {

  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

const ProjectList = props => {
  const dispatch = useDispatch()
  const { projectsLoaded, sort, isHeb, lineLength, scroll, filteredProjects } = useSelector(state => state.project)
  const [boxSize, setSize] = useState(window.innerWidth / lineLength)
  const mounted = useRef(false)
  let firstLine = sort.type === 'dep' ? lineLength - 3 : lineLength - 2
  let lastLine = lineLength
  let color = ''
  let dir = 'horizontal'
  
  // useEffect(() => {
  //   let ttl = localStorage.getItem('projectsTtl') ? JSON.parse(localStorage.getItem('projectsTtl')) : 0
  //   let now = new Date().getTime()
  //   if(projects.length === 0 || now > ttl) {
  //     socket.emit(SOCK_GET_PROJECTS)
  //   }

  //   // if (projects.length === 0) {
  //   //   localStorage.setItem('projects', JSON.stringify(projectData))
  //   //   dispatch(addProjects([...projectData]))
  //   //   window.location.reload()
  //   // }

  //   socket.on(SOCK_GET_PROJECTS, data => {
  //     const { addProjects } = ProjectActions
  //     let newttl = new Date()
  //     newttl.setMinutes(newttl.getMinutes() + 60)
  //     localStorage.setItem('projects', JSON.stringify(data.projects))
  //     localStorage.setItem('projectsTtl', JSON.stringify(newttl.getTime()))
  //     dispatch(addProjects(data.projects))
  //     window.location.reload()
  //   })
  // }, [dispatch, projects.length])

  useEffect(() => {
    mounted.current = true
    animateScroll.scrollTo(scroll, { smooth: false, duration: 0.1, isDynamic: true})
    return () => mounted.current = false
  }, [scroll])

  useEffect(() => {
    let scrollWidth = getScrollbarWidth()
    if(!isMobile) {
      if(hasScrollbar()){
        mounted.current && setSize((window.innerWidth - (50 + scrollWidth)) / lineLength)
      }
      else {
        mounted.current && setSize((window.innerWidth - 50) / lineLength)
      }
    }
    else mounted.current && setSize(window.innerWidth / lineLength)
  }, [boxSize, lineLength])

  function handleSetProject(project) {
    let name = project.projectName.length > 0 ? project.projectName : project.hebProjectName

    if(name.length === 0 || !name) {
      if(project.department === 'Multidisciplinary Art School') {
        name = project.students[0].name.length > 0 ? project.students[0].name : project.students[0].hebName
      }
    }

    name = name.replace(/[?!:|*#%><,"'“”‘’/\\]/g, '')
    name = name.replace(/[\u0591-\u05C7]/g, '')
    name = name.trim()

    props.history.push(`/project/${name}`)
  }

  function handleSetSpeech(project) {
    const dep = project.department
    props.history.push(`/speech/${dep}`)
  }

  function renderMask(value, index, tempProjects, tempIndex) {
    let { projectName, department, hebDepartment, hebProjectName } = value

    projectName = projectName.length === 0 ? hebProjectName : projectName
    if(projectName.length === 0) {
      projectName = value.students[0].hebName
    }
    projectName = projectName.replace(/[?!:|*><,"'“”‘’/\\]/g, '')
    projectName = projectName.replace(/[\u0591-\u05C7]/g, '')
    projectName = projectName.trim()

    dir = dir === 'vertical' ? 'horizontal' : 'vertical'
    let url
    try {
      url = require(`../../Images/${department}/${projectName}/0.jpg`)
    }
    catch(err) {
      projectName = hebProjectName
      projectName = projectName.replace(/[?!:|*><,"'“”‘’/\\]/g, '')
      projectName = projectName.replace(/[\u0591-\u05C7]/g, '')
      projectName = projectName.trim()

      try {
        url = require(`../../Images/${department}/${projectName}/0.jpg`)
      }
      catch {
        try {
          url = require(`../../Images/default/${department}.jpg`)
        }
        catch {
          url = require(`../../Images/default/gray.png`)
        }
      }
    }

    if(index === 0) {
      if(sort.type === 'dsep') {
        dir = dir === 'vertical' ? 'horizontal' : 'vertical'
        let length = tempProjects[tempIndex].length + 3
        let roomLast = lineLength - Math.abs(lastLine)


        if(roomLast === 1) {
          roomLast = lineLength
          dir = dir === 'vertical' ? 'horizontal' : 'vertical'
        }

        /* update values for next list */
        firstLine = roomLast > length ? roomLast - length : roomLast

        if(length < lineLength - lastLine) {
          lastLine += length
        }
        else lastLine = Math.abs(length - firstLine) % lineLength
      }
      else {
        let length = tempProjects[tempIndex].length + 2
        let roomLast = lineLength - Math.abs(lastLine)

        if(roomLast === 1) {
          roomLast = 0
          dir = dir === 'vertical' ? 'horizontal' : 'vertical'
        }

        /* update values for next list */
        firstLine = roomLast
        if (length < lineLength - lastLine) {
          lastLine = lastLine + length
        }
        else lastLine = Math.abs(length - firstLine) % lineLength
      }
    }

    let text = ''

    if(index === 0) {
      if(sort.type === 'dep'){
        text = isHeb ? value.hebDepartment : value.department
        if (value.hebDepartment === 'הנדסת תעשייה וניהול') {
          text = isHeb ? 'בית-הספר להנדסת תעשייה וניהול' : 'School of Industrial Engineering & Management'
        }
      }
      else {
        let tempTag = additionalTags.find(tag => tag.new === value.tags[0].trim())
        if(tempTag){
          text = tempTag.old
        }
        else {
          let tagIndex = tagsArray.findIndex(tag => tag === value.tags[0].trim())
          text = isHeb ? tagsArray[tagIndex] : englishTags[tagIndex]
        }
      }
    }

    const speechboxclass = isHeb ? 'hebSpeechBoxHolder' : 'speechBoxHolder'
    const hasSpeech = Speeches[hebDepartment].text.length === 0 ||  sort.type !== 'dep' ? false : true
    color = colors[value.tags[0]?.trim()]

    return (
      <div key={`fullmask${value.department}${index}`}>
        {
          index === 0 && (
            <div>
              <div 
                key={`title${value.department}${index}`} 
                style={{ 
                  float: isHeb ? 'right' : 'left', 
                  width: boxSize * 2, 
                  height: boxSize,
                  cursor: hasSpeech ? 'pointer' : 'default'
                }}
                onClick={hasSpeech && sort.type === 'dep' ? 
                  () => {
                    mounted.current && handleSetSpeech(value) 
                    mounted.current && dispatch(ProjectActions.setScroll(window.scrollY))
                  } 
                  : 
                  null
                }
              >
                <p
                  style={{ 
                    color: sort.type === 'dep' ? '#626262' : color, 
                    fontSize: sort.type === 'dep' && !isHeb ? `${(boxSize)}%` : `${(boxSize * 1.1)}%`
                  }}
                  className="departmentBox"
                >
                  {text}
                </p>
              </div>
              {/* {
                sort.type === 'dep' && 
                  <div
                    style={{
                      float: isHeb ? 'right' : 'left',
                      width: boxSize,
                      height: boxSize,
                      cursor: 'pointer'
                    }}
                    onClick={() => mounted.current && setSpeech(value)}
                  > 
                    <div className={speechboxclass}> 
                        <p
                          style={{ fontSize: `${1.5 / (lineLength / 9)}vw` }}
                          dir={isHeb ? 'rtl' : 'ltr'}
                          className='speechBox'
                        >
                        {isHeb ? 'דבר ראש המחלקה' : 'Head of Department Speech'}
                        </p>
                    </div>
                  </div>
              } */}
            </div>
          )
        }
        <div 
          key={`mask${index}`} 
          style={{
            float: isHeb ? 'right' : 'left', width: boxSize, height: boxSize,
          }}
          onClick={() => { 
            mounted.current && !isMobile && dispatch(ProjectActions.setScroll(window.scrollY))
            mounted.current && !isMobile && handleSetProject(value)
          }}
        >
          <MaskPicture
            mainList={true}
            color={color}
            direction={dir}
            url={url}
            project={value}
            index={index}
            setProject={handleSetProject}
            incLoaded={props.incLoaded}
          />
        </div>
      </div>
    )
  }

  function renderLoaded() {
    const tempProjects = []
    let sortedArray = []

    if(sort.type === 'dep') {
      englishDep.map(dep => {
        let tempFilter = filteredProjects.filter(project => project.department === dep)

        if(tempFilter.length === 0) {

        }

        tempProjects.push(tempFilter)
        return null
      })
    }
    else {
      /* sort by tags + additinal tags and swap index to top of the list */
      tagsArray.map(tag => tempProjects.push(filteredProjects.filter(project => {
        if(project.tags.length === 0) {
          return false
        }

        let tempTag = project.tags[0]
        tempTag = tempTag.trim()
        /* check in additinal */
          for(let i = 0; i < additionalTags.length; ++i) {
            if(additionalTags[i].old === tag && additionalTags[i].new === tempTag) {
              return true
            }
          }
          return tempTag === tag
      })))
      // let count = 0
      // tempProjects.map(projects => count += projects.length)
    }

    /* change order by sort.index */
    let firstPart = tempProjects.slice(sort.index, tempProjects.length)
    let secondPart = tempProjects.slice(0, sort.index)
    sortedArray = [...firstPart, ...secondPart]

    // let temp1 = []
    // let temp2 = []

    // tempProjects.map(array => temp1 = [...temp1, ...array])
    // tempSort.map(array => temp2 = [...temp2, ...array])
    // console.log(`temp1: ${temp1.length} temp2: ${temp2.length}`)
    // temp2.map(project => temp1 = temp1.filter(project1 => project1.projectName !== project.projectName))
    // console.log(`temp1 after filter: ${temp1.length}`)
    // console.log(temp1)

    return (
      <div>
        {sortedArray.map((projects, tempIndex) => {
          if(!projects.length) {
            return null
          }

          const tempProjects = projects.sort((a, b) => {
            let nameA = isHeb ? a.hebProjectName.toUpperCase() : a.projectName.toUpperCase()
            let nameB = isHeb ? b.hebProjectName.toUpperCase() : b.projectName.toUpperCase()

            if (nameA < nameB) {
              return -1
            }

            if (nameA > nameB) {
              return 1
            }

            return 0
          })

          return (
            <div key={`maskList${tempIndex}`}>
              {tempProjects.map((value, index) => renderMask(value, index, sortedArray, tempIndex))}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div 
      dir={dir} 
      className={isMobile ? 'mobileFlexBox' : (isHeb ? 'hebFlexBox' : 'flexBox')} 
      style={{zIndex: -1, width: isMobile ? '100vw' : 'calc(100vw - 50px)'}}
    >
      {projectsLoaded ? renderLoaded() : null}
    </div>
  )
}

export default withRouter(ProjectList)
