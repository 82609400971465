import { combineReducers } from 'redux'
import ProjectReducer from './ProjectReducer'
import SnackbarReducer from './SnackbarReducer'

const appReducer = combineReducers({
  project: ProjectReducer,
  snackbar: SnackbarReducer,
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer 
