const n0 = 'נגישות'
const n1 = 'בריאות'
const n2 = 'אורבני'
const n21 = 'אורבניות'
const n3 = 'קורונה'
const n4 = 'טבע ובעלי חיים'
const n41 = 'טבע'
const n42 = 'בעלי חיים'
const n5 = 'מדע וטכנולוגיה'
const n51 = 'מדע'
const n52 = 'טכנולוגיה'
const n6 = 'רוח ונפש'
const n7 = 'קראפט'
const n8 = 'זהות'
const n9 = 'זיכרון'
const n91 = 'זכרון'
const n10 = 'דימוי גוף'
const n11 = 'ריהוט'
const n12 = 'בית'
const n13 = 'ילדים ונוער'
const n131 = 'ילדים'
const n14 = 'חברה'
const n15 = 'מגדר'
const n16 = 'קיימות'
const n17 = 'תעשייה'
const n18 = 'ביטחון'
const n19 = 'פוליטיקה'

export const colors = new Map()

colors[n0] = '#59E898'
colors[n1] = '#60DCA7'
colors[n2] = '#69CCBC'
colors[n21] = '#69CCBC'
colors[n3] = '#73BBD2'
colors[n4] = '#7CB0D7'
colors[n41] = '#7CB0D7'
colors[n42] = '#7CB0D7'
colors[n5] = '#89A0DE'
colors[n51] = '#89A0DE'
colors[n52] = '#89A0DE'
colors[n6] = '#9392E4'
colors[n7] = '#A190D9'
colors[n8] = '#B38ECB'
colors[n9] = '#C68BBC'
colors[n91] = '#C68BBC'
colors[n10] = '#D28CAF'
colors[n11] = '#E98F96'
colors[n12] = '#FF927E'
colors[n13] = '#FF9C7C'
colors[n131] = '#FF9C7C'
colors[n14] = '#FFAC78'
colors[n15] = '#FFBC74'
colors[n16] = '#FFC671'
colors[n17] = '#F5D568'
colors[n18] = '#EFDF62'
colors[n19] = '#E6EC5A'

export const colorArray = [
  '#59E898', '#60DCA7', '#69CCBC', '#73BBD2', '#7CB0D7', '#89A0DE',
  '#9392E4', '#A190D9', '#B38ECB', '#C68BBC', '#D28CAF', '#E98F96',
  '#FF927E', '#FF9C7C', '#FFAC78', '#FFBC74', '#FFC671', '#F5D568',
  '#EFDF62', '#E6EC5A'
]

export const hebrewDep = [
  'עיצוב אופנה',
  'עיצוב תעשייתי',
  'תקשורת חזותית',
  'עיצוב טקסטיל',
  'עיצוב תכשיטים',
  'עיצוב פנים מבנה וסביבה',
  'התואר השני בעיצוב - כיתת המהנדסים',
  'תואר שני בעיצוב',
  'בית-הספר לאמנות רב-תחומית',
  'הנדסת חשמל ואלקטרוניקה',
  'הנדסת חומרים פולימרים',
  'תואר שני בהנדסת חומרים פולימרים',
  'הנדסת תוכנה',
  'הנדסת תעשייה וניהול',
  'הנדסה כימית'
]

export const englishDep = [
  'Fashion Design',
  'Industrial Design',
  'Visual Communication',
  'Textile Design',
  'Jewelry Design',
  'Interior Building & Environment',
  'Master Degree in Design - Engineers Class',
  'Master Degree in Design',
  'Multidisciplinary Art School',
  'Electrical & Electronic Engineering',
  'The Department of Polymer Materials Engineering',
  'Master Degree Polymer Materials Engineering',
  'Software Engineering',
  'Industrial Engineering & Management',
  'Chemical Engineering'
]

export const SOCK_HOST = 'https://shenkar-grad-2020-cms.herokuapp.com'
export const SOCK_GET_PROJECTS = 'getProjects'
export const headerTitle = 'Shenkar Graduation 2020'
export const hebHeaderTitle = 'סופשנה שנקר 2020'
export const hebDepButtonText = 'מחלקה'
export const hebTopicButtonText = 'נושא'
export const depButtonText = 'Department'
export const topicButtonText = 'Topic'
export const tagsArray = [n0, n1, n2, n3, n4, n5, n6, n7, n8, n9, n10, n11, n12, n13, n14, n15, n16, n17, n18, n19]
export const englishTags = [
  'Accessibility', 'Health', 'Urban',
  'Covid-19', 'Nature and animals', 'Science and technology',
  'Mind and spirit', 'Craft', 'Identity',
  'Memory', 'Body image', 'Furniture',
  'Home', 'Children and youth', 'Society',
  'Gender', 'Sustainability', 'Industry',
  'Security', 'Politics',

]
export const additionalTags = [
  {new: n21, old: n2}, 
  {new: n41, old: n4}, 
  {new: n42, old: n4}, 
  {new: n51, old: n5}, 
  {new: n52, old: n5}, 
  {new: n91, old: n9},
  {new: n131, old: n13}
]
