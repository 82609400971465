import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { englishDep, hebrewDep, tagsArray, colors, englishTags } from '../consts'
import projectActions from '../../Actions/ProjectActions'

const MobileOverlay = props => {
  const { onClose, type, sendBack, projectShown } = props
  const { isHeb } = useSelector(state => state.project)
  const [style, setStyle] = useState({color: '#FFFFFF'})
  const dispatch = useDispatch()

  useEffect(() => {
    let tempStyle = {
      color: '#FFFFFF',
      width: '100%',
      height: '75px',
      fontSize: '18px',
      fontWeight: 'lighter'
    }

    if(type === 'dep') {
      tempStyle.backgroundColor = '#4D4D4D'
      tempStyle.boxShadow = '0px 1px 6px #00000029'
    }

    setStyle(tempStyle)
  }, [type])

  function handleClick(index) {
    const { setSort, setScroll } = projectActions
    dispatch(setSort({ type: type, index: index }))
    dispatch(setScroll(0))
    onClose()
    sendBack()
  }

  function eachButton(value, index) {
    let tempStyle = {...style}
    if(type === 'topic') {
      tempStyle.backgroundColor = colors[tagsArray[index]]
    }

    return (
      <button 
        style={tempStyle} 
        key={`button${value}${index}`} 
        className='invisButton'
        onClick={() => handleClick(index)}
      >
        {value}
      </button>
    )
  }

  function renderDepList() {
    const deps = isHeb ? hebrewDep : englishDep
    return deps.map(eachButton)
  }

  function renderTagList() {
    const tags = isHeb ? tagsArray : englishTags
    return tags.map(eachButton)
  }

  return (
    <div 
      style={{ height: '92vh', width: '100vw', overflowY: 'scroll', position: 'relative', top: projectShown ? '-1px' : '-1px'}} 
      className='mobileOverlay'
    >
      {type === 'dep' ? renderDepList() : renderTagList()}
    </div>
  )
}

export default MobileOverlay