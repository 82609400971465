import useSound from 'use-sound'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import ProjectActions from '../../Actions/ProjectActions'
import React, { useEffect, useState, useRef } from 'react'

const MaskPicture = prop => {

  const dispatch = useDispatch()
  const { url, color, direction, project, index, incLoaded, mainList, setProject, recommended } = prop
  const { students } = project
  
  const { selectProject } = ProjectActions
  const { isHeb, lineLength } = useSelector(state => state.project)
  const [names, setNames] = useState([])
  const [first, setFirst] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  })
  
  const [second, setSecond] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  })
  
  const middle = {
    height: '100%',
    width: '100%',
    x: 0,
    y: 0
  }
  
  const [hovered, setHovered] = useState(false)
  const mounted = useRef(false)
  const [play, {stop}] = useSound(mounted ? require(`../../Sounds/${(index % 73) + 1}.wav`) : null)
  
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current && stop()
      mounted.current = false
    }
  }, [stop])

  useEffect(() => {
    let studentNames = []
    students.map(student => studentNames.push(isHeb ? student.hebName : student.name))
    mounted.current && setNames(studentNames)
    if (direction === 'horizontal'){
      mounted.current && setFirst({
        height: '40%',
        width: '100%',
        x: 0,
        y: 0
      })
      mounted.current && setSecond({
        height: '40%',
        width: '100%',
        x: 0,
        y: '60%'
      })
    }
    else {
      mounted.current && setFirst({
        height: '100%',
        width: '40%',
        x: 0,
        y: 0
      })
      mounted.current && setSecond({
        height: '100%',
        width: '40%',
        x: '60%',
        y: 0
      })
    }
  }, [prop, direction, isHeb, students])
  
  function renderMidRect(isMask) {
    const style = isMask ? 
    {
      fill: color,
      mixBlendMode: 'screen'
    }
    :
    { 
      fill: 'black', 
      opacity: 0.3,
    }

    return (
      <rect
        style={style}
        width={middle.width}
        height={middle.height}
        x={middle.x}
        y={middle.y}
        className={hovered ? 'maskFadeOut' : 'maskFadeIn'}
      />
    )
  }

  function renderFirstRect() {
    const className = direction === 'horizontal' ? 'firstFadeOutHorizontal' : 'firstFadeOutVertical'

    return (
      <rect
        style={{fill: '#FFFFFF'}}
        width={first.width}
        height={first.height}
        x={first.x}
        y={first.y}
        className={hovered ? className : 'rectFadeIn'}
      />
    )
  }

  function renderSecondRect() {
    const className = direction === 'horizontal' ? 'secondFadeOutHorizontal' : 'secondFadeOutVertical'

    return (
      <rect
        style={{fill: '#FFFFFF'}}
        width={second.width}
        height={second.height}
        x={second.x}
        y={second.y}
        className={hovered ? className : 'rectFadeIn'}
      />
    )
  }

  function renderNames() {
    const fontSize = isMobile ? (recommended ? 13 : Math.abs(16 - lineLength)) : recommended ? '1.3vw' : `calc(2vw - ${lineLength}px)`
    return (
      names.map((name, index) => 
        <text
          x='50%'
          y={isMobile ? `${50 - ((names.length - 1) * 5) + ((index * 10) + index * 3)}%` : `${50 - ((names.length - 1) * 5) + (index * 10 + index * 3)}%`}
          dominantBaseline='middle'
          textAnchor='middle'
          key={`name${index}`}
          className={hovered ? 'textFade' : 'textFadeOut'}
        >
          <tspan
            style={{ fontWeight: 'bold', fontSize: fontSize }}
            fill='#FFFFFF'
          >
            {name}
          </tspan>
        </text>
      )
    )
  }
  
  function handleOnHover() {
    mounted.current && !isMobile && play()
    mounted.current && setHovered(true)
  }

  function handleOnLeave() {
    mounted.current && setHovered(false)
    mounted.current && dispatch(selectProject(undefined))
  }

  function mobileHandleClick() {
    if(hovered) {
      mounted.current && dispatch(ProjectActions.setScroll(window.scrollY))
      mounted.current && setProject(project)
    }
    else { 
      const interval = setInterval(() => {
        mounted.current && setHovered(false)
        clearInterval(interval)
      }, 3000)
      mounted.current && setHovered(true)
    }
  }

  const boxShadow = isHeb ? '2px 0px 1px #00000029' : '-2.05px 0px 1px #00000029'

  const boxStyle = { 
    boxShadow: direction === 'vertical' ? boxShadow : 'none', 
    background: direction === 'vertical' ? 'var(--unnamed-color-ffffff) 0 % 0 % no-repeat padding-box' : 'none', 
    width: '100%', 
    height: '100%',
    opacity: 1,
  }

  return (
    <div
      className='maskPicture'
      style={boxStyle}
    >
      <svg
        height='100%'
        width='100%'
      >
        <image
          href={url}
          onLoad={incLoaded}
          preserveAspectRatio='xMidYMid slice'
          x='0'
          y='0'
          width='100%'
          height='100%'
        />
        <g>
          <g>
            {hovered && renderMidRect(false)}
            {hovered && renderNames()}
          </g>
          
          {renderMidRect(true)}
          {mainList && renderFirstRect()}
          {mainList && renderSecondRect()}

          {!isMobile &&
            <rect
            style={{ opacity: 0, cursor: 'pointer' }}
            width={'100%'}
            height={'100%'}
            x={0}
            y={0}
            onMouseOver={handleOnHover}
            onMouseLeave={handleOnLeave}
            onTouchStart={handleOnHover}
            onTouchEnd={handleOnLeave}
          />}
          {isMobile && 
            <rect
              style={{ opacity: 0, cursor: 'pointer' }}
              width={'100%'}
              height={'100%'}
              x={0}
              y={0}
              onClick={mobileHandleClick}
            />
            }
        </g>
      </svg>
    </div>
  )
}

export default MaskPicture
