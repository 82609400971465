import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import MaskPicture from '../Utilities/MaskPicture'
import React, { useEffect, useState, useRef } from 'react'
import { colors, tagsArray, additionalTags } from '../consts'

const RecommendedList = props => {

  const [index, setIndex] = useState(0)
  const { department, project } = props
  const [addProjects, setProjects] = useState(undefined)
  const { projects, isHeb } = useSelector(state => state.project)

  const addText = isHeb ? 'הצעות נוספות' : 'Additional Suggestions'
  const recommendedText = isHeb ? 'מומלצי המחלקה' : 'Recommended Projects'
  const align = isHeb ? { textAlign: 'right' } : { textAlign: 'left' }
  const dir = isHeb ? 'rtl' : 'ltr'
  const width = window.innerWidth >= 1920 ? 1440 : window.innerWidth
  const boxSize = isMobile ? (width * 0.7) / 2 : (width * 0.75) / 8

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    let list = []
    tagsArray.map(tag => {
      const filtered = projects.filter(project => {
        if (project.tags.length === 0) {
          return false
        }

        let tempTag = project.tags[0]
        tempTag = tempTag.trim()
        /* check in additinal */
        for (let i = 0; i < additionalTags.length; ++i) {
          if (additionalTags[i].old === tag && additionalTags[i].new === tempTag) {
            return true
          }
        }

        return tempTag === tag
      })

      let tempIndex = Math.floor(Math.random() * Math.floor(filtered.length))
      if (filtered[tempIndex] === undefined) {
        return null
      }

      list.push(filtered[tempIndex])

      return null
    })

    setProjects(list)
    setIndex(0)

    return () => mounted.current = false
  }, [project, projects])

  const textStyle = {
    fontSize: '18px',
    fontWeight: 400,
    color: '#626262',
    marginBottom: '40px',
    ...align
  }

  function handleSetProject(project) {
    let name = project.projectName.length > 0 ? project.projectName : project.hebProjectName
    name = name.replace(/[?!:|*#%><,"'“”‘’/\\]/g, '')
    name = name.replace(/[\u0591-\u05C7]/g, '')
    name = name.trim()

    props.history.push(`/project/${name}`)
  }

  function eachProject(project, i) {
    let { projectName, department, hebProjectName} = project

    projectName = projectName.replace(/[?!:|*><,"/\\]/g, '')
    projectName = projectName.replace(/[\u0591-\u05C7]/g, '')
    projectName = projectName.trim()

    let url

    try {
      url = require(`../../Images/${department}/${projectName}/0.jpg`)
    }
    catch (err) {
      projectName = hebProjectName
      projectName = projectName.replace(/[?!:|*><,"'“”‘’/\\]/g, '')
      projectName = projectName.replace(/[\u0591-\u05C7]/g, '')
      projectName = projectName.trim()

      try {
        url = require(`../../Images/${department}/${projectName}/0.jpg`)
      }
      catch {
        try {
          url = require(`../../Images/default/${department}.jpg`)
        }
        catch {
          url = require(`../../Images/default/gray.png`)
        }
      }
    }

    let color = colors[project.tags[0].trim()]
    const margin = i > 0 ? `${10 / 8}%` : 0

    const style = {
      marginRight: isHeb ? margin : margin, 
      marginLeft: isHeb ? 0 : 0,
      float: isHeb ? 'right' : 'right', 
      width: boxSize, 
      height: boxSize
    }

    const mobileStyle = {
      width: boxSize, 
      height: boxSize,
      marginLeft: isHeb ? (index % 2 ? 0 : '7%') : (index % 2 ? 0 : '7%'),
      marginRight: isHeb ? 0 : 0,
      marginBottom: '7%',
      float: isHeb ? 'right' : 'right'
    }

    return (
      <div
        key={`additinalMask${i}`}
        style={isMobile ? mobileStyle : style}
        onClick={() => {
          mounted.current && !isMobile && handleSetProject(project)
        }}
      >
        <MaskPicture
          color={color}
          direction={dir}
          url={url}
          setProject={handleSetProject}
          project={project}
          index={i}
          recommended={true}
        />
      </div>
    )
  }

  function renderButtons() {
    const style = {
      fontSize: '2em',
      position: 'relative',
      top: (boxSize / 2) + 42,
    }

    const leftFunc = isHeb ? handleLeft : handleRight
    const rightFunc = isHeb ? handleRight : handleLeft

    return (
      <div>
        <button 
          onClick={leftFunc} 
          className='invisButton' 
          style={{...style, float: 'left', paddingLeft: '40px'}}
        >
          <svg width='1em' height='1em' viewBox='0 0 16 16' className='bi bi-chevron-left' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z' />
          </svg>
        </button>
        <button onClick={rightFunc} className='invisButton' style={{...style, float: 'right', paddingRight: '40px'}}>
          <svg width='1em' height='1em' viewBox='0 0 16 16' className='bi bi-chevron-right' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z' />
          </svg>
        </button>
      </div>
    )
  }

  function renderList() {
    let tempList = addProjects
    let endIndex = (index + 1) * 6 > addProjects.length ? addProjects.length : (index + 1) * 6

    if(department === undefined) {
      tempList = addProjects.slice(index * 6, endIndex)
    }

    if(isMobile) {
      if(tempList.length > 6) {
        let tempIndex = Math.floor(Math.random() * Math.floor(addProjects.length))
        
        while(tempIndex + 6 >= addProjects.length) {
          tempIndex = Math.floor(Math.random() * Math.floor(addProjects.length))
        }

        let endIndex = tempIndex + 6 >= addProjects.length ? addProjects.length : tempIndex + 6
        tempList = addProjects.slice(tempIndex, endIndex)
      }
    }

    return tempList.map(eachProject)
  }

  function renderAdditional() {
    const containerClass = isMobile ? 'mobileContainerRecommended' : 'containerRecommended'
    

    return (
      <div style={{ width: '100vw' }}>
        {!isMobile && renderButtons()}
        <div className='container' style={{ width: isMobile ? '90%' : '100vw'}}>
          <p dir={dir} style={textStyle}>{addText}</p>
        </div>
        {isMobile && 
          <div className={containerClass}>
            {addProjects && renderList()}
          </div>
        }
        {!isMobile && 
        // <div className='container'>
          <div className={containerClass}>
            {addProjects && renderList()}
          </div>
        // </div>
        }
      </div>
    )
  }

  function renderDepRecommended() {
    return (
      <div className='container'>
        <p dir={dir} style={textStyle}>{recommendedText}</p>
        {renderList()}
      </div>
    )
  }

  function handleLeft() {
    if(index === 3) {
      setIndex(0)
      return
    }

    setIndex(index + 1)
  }

  function handleRight() {
    if (index === 0) {
      setIndex(3)
      return
    }

    setIndex(index - 1)
  }

  return (
    <div style={{marginTop: '40px', marginBottom: '20px', height: isMobile ? '550px' : '250px'}} className='recommendedList'> 
      {department ? renderDepRecommended() : renderAdditional()}
    </div>
  )
}

export default withRouter(RecommendedList)