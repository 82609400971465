import Header from './Utilities/Header'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import SideBar from './Utilities/SideBar'
import Loading from './Utilities/Loading'
import { animateScroll } from 'react-scroll'
import ProjectList from './Project/ProjectList'
import ProjectPage from './Project/ProjectPage'
import SpeechPage from './Project/SpeechPage'
import TopicOverlay from './Utilities/TopicOverlay'
import SearchOverlay from './Utilities/SearchOverlay'
import DepartmentsOverlay from './Utilities/DepartmentsOverlay'
import { withRouter } from 'react-router'

const HomePage = props => {
  const [selectedProject, setProject] = useState(undefined)
  const [speech, setSpeech] = useState(undefined)
  const [loaded, setLoaded] = useState(false)
  const [overlay, setOverlay] = useState(undefined)
  const { isHeb, projects, scroll } = useSelector(state => state.project)

  useEffect(() => {
    let project = props.match.params.project
    let speech = props.match.params.speech

    if(project !== undefined) {
      project = decodeURI(project)
      const tempProject = projects.find(value => {
        let tempName = value.projectName.length === 0 ? value.hebProjectName : value.projectName

        if(tempName.length === 0) {
          tempName = value.students[0].name.length > 0 ? value.students[0].name : value.students[0].hebName
        }

        tempName = tempName.replace(/[?!:|*><,"%#“'`”‘’/\\]/g, '')
        tempName = tempName.replace(/[\u0591-\u05C7]/g, '')
        tempName = tempName.trim()
        project = project.replace(/[?!:|*><%#,"“'`”‘’/\\]/g, '')
        project = project.replace(/[\u0591-\u05C7]/g, '')
        project = project.trim()

        return tempName === project
      })

      if(tempProject === undefined) {
        setProject(undefined)
        props.history.push('/')
      }
      else setProject(tempProject)
    }
    else setProject(undefined)

    if(speech !== undefined) {
      speech = decodeURI(speech)
      setSpeech(speech)
    }
    else setSpeech(undefined)

  }, [props])

  function handleSendBack(speech) {
    setProject(undefined)
    setSpeech(undefined)
    speech && animateScroll.scrollTo(scroll, { smooth: false, duration: 0.1, isDynamic: true })
    props.history.push('/')
  }

  function handleSetProject(value) {
    setProject(value)
    setSpeech(undefined)
  }

  function handleSetSpeech(value) {
    setProject(undefined)
    setSpeech(value)
  }

  function incLoaded() {
    // setImagesLoaded(imagesLoaded + 1)
  }

  function renderList() {
    return (
      <div>
        <SideBar />
        <ProjectList incLoaded={incLoaded} setProject={handleSetProject} setSpeech={handleSetSpeech}/>
      </div>
    )
  }

  function renderSpeechPage () {
    return (
      <SpeechPage 
        speech={speech}
        onClose={handleSendBack}
        isHeb={isHeb}
      />
    )
  }

  function renderProject() {
    return (
      <ProjectPage
        setProject={handleSetProject}
        project={selectedProject}
        onClose={handleSendBack}
        isHeb={isHeb}
      />
    )
  }

  function renderNonList() {
    return selectedProject ? renderProject() : renderSpeechPage()
  }

  function renderLoading() {
    return <Loading projectShown={selectedProject !== undefined} setLoaded={setLoaded}/>
  }

  return (
    <div>
      {renderLoading()}
      <div className='homePage'>
        <div>
          { loaded && 
          <Header 
            sendBack={handleSendBack} 
            overlay={overlay} 
            setOverlay={setOverlay}
            projectShown={selectedProject !== undefined}
          />
          }
          <TopicOverlay 
            changedSort={handleSendBack} 
            projectShown={selectedProject !== undefined} 
            shown={overlay === 'topic'} 
            onClose={() => setOverlay(undefined)} 
          />
          <DepartmentsOverlay
            changedSort={handleSendBack}  
            projectShown={selectedProject !== undefined} 
            shown={overlay === 'dep'} 
            onClose={() => setOverlay(undefined)} 
          />
          <SearchOverlay 
            changedSort={handleSendBack}
            projectShown={selectedProject !== undefined}
            shown={overlay === 'search'}
            onClose={() => setOverlay(undefined)} 
          />
        </div>
        {selectedProject || speech ? renderNonList() : renderList()}
      </div>
    </div>
  )
}

export default withRouter(HomePage)