import { Img } from 'react-image'
import { MDBIcon } from 'mdbreact'
import Footer from '../Utilities/Footer'
import ImageCarousel from './ImageCarousel'
import { animateScroll } from 'react-scroll'
import { colors, additionalTags } from '../consts'
import useKeyPress from '../Utilities/useKeyPress'
import SquareLoader from 'react-spinners/SquareLoader'
import React, { useEffect, useState, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import RecommendedList from './RecommendedList'

const ProjectPage = props => {
  const { onClose, project, isHeb, setProject } = props
  let tag = project.tags[0]?.trim()

  let tempTag = additionalTags.find(tag => tag.new === project.tags[0])
  if (tempTag) {
    tag = tempTag.old
  }
  const [showImages, setShowImages] = useState(false)
  const [currentPhoto, setCurrentPhoto] = useState('')

  const align = isHeb ? { textAlign: 'right' } : { textAlign: 'left' } 
  const dir = isHeb ? 'rtl' : 'ltr'
  const escapePress = useKeyPress('Escape')
  const color = colors[tag]
  const mounted = useRef(false)

  useEffect(() => {
    if(escapePress) {
      if(showImages) {
        setShowImages(false)
        setCurrentPhoto('')
      }
      else onClose()
    }
  }, [escapePress, onClose, showImages])

  useEffect(() => {
    mounted.current = true
    animateScroll.scrollTo(0, { smooth: false, duration: 0.1, isDynamic: true })
    return () => mounted.current = false
  }, [project])

  function getNames() {
    const { students } = project
    let names = []

    students.map(student => names.push(isHeb ? student.hebName : student.name))
    return names
  }

  function renderButton(onClose) {
    
    return (
    <div
      style={{ 
        width: 'calc(100% - 17px)', 
        height: '25px',
        marginBottom: isMobile ? '2px' : '33px',
        marginTop: isMobile ? '17px' : '34px', 
        marginLeft: isMobile ? '17px' : '34px', 
      }}
    >
      <img
        onClick={onClose}
        style={{width: '23px', height: '23px', cursor: 'pointer'}}
        src={require('../../Images/x.svg')}
        alt="x"
      />
    </div>
      // <button className={className} onClick={onClose}>
      //   <img
      //     onClick={onClose}
      //     src={require('../../Images/x.svg')}
      //     alt="x"
      //   />
      // </button>
    )
  }
    // return (
    // // <button className={className} onClick={onClose}>
    // <div
    //   onClick={onClose} 
    //   style={{ 
    //     width: 'calc(100% - 17px)', 
    //     height: '25px',
    //     cursor: 'pointer', 
    //     marginBottom: isMobile ? '2px' : '33px',
    //     marginTop: isMobile ? '17px' : '34px', 
    //     marginLeft: isMobile ? '17px' : '34px', 
    //   }}
    // >
    //   <img
    //     // className={className}
    //     style={{opacity: 1}}
    //     src={require('../../Images/x.svg')}
    //     alt="x"
    //   />
    // </div>
    // // </button>
    // )
  // }

  function renderPortfolio(student) {
    let { portfolio } = student
    portfolio = portfolio.trim()
    let isGit = false
    if(!portfolio.includes('https://') && portfolio.length !== 0) {
      portfolio = 'https://' + portfolio
    }

    if (portfolio.includes('git')) {
      isGit = true
    }

    const textStyle = {
      ...align,
      fontSize: '18px',
      color: '#4D4D4D',
      padding: 0,
      marign: 0,
      lineHeight: 1,
      fontWeight: 'lighter',
      letterSpacing: '0.36px',
      textDecoration: 'underline',
    }

    const iconStyle = {
      display: 'block',
      position: 'relative',
      color: 'inherit',
      float: isHeb ? 'right' : 'left',
      width: '20px',
    }

    return portfolio.length !== 0 ? (
      isGit ? 
      (
        <a style={iconStyle} target='_blank' rel="noopener noreferrer" href={portfolio}>
          <MDBIcon style={{float: isHeb ? 'right' : 'left'}} fab icon='github' />
        </a>
      )
      :
        <a target='_blank' rel="noopener noreferrer" href={portfolio}><p style={textStyle}>Portfolio</p></a>
    )
    :
    null
  }

  function renderSocial(student) {
    const icons = ['facebook-f', 'instagram', 'linkedin']
    const { socialNet } = student
    const tempSocial = socialNet.split(' ').join('').split(',')

    const iconStyle = {
      display: 'inline-block',
      position: 'relative',
      color: 'inherit',
      // left: isHeb ? '94%' : '0',
      // right: isHeb ? '0' : '94%',
      float: isHeb ?  'right' : 'left',
      width: '20px',
    }

    return tempSocial.map(social => {
      let icon = undefined
      let tempStyle = {...iconStyle}
      if(social.length === 0) {
        return null
      }

      if(!social.includes('http')){
        social = 'https://' + social
      }

      if (social.includes('facebook')) {
        icon = 0
      }
      else if (social.includes('instagram')) {
        icon = 1
      }
      else if (social.includes('linkedin')) {
        icon = 2
      }

      return (
        <a key={`social${social}`} style={tempStyle} target='_blank' rel="noopener noreferrer" href={social}>
          <MDBIcon style={{float: isHeb ? 'right' : 'left'}} fab icon={icons[icon]} />
        </a>
      )

    })
  }

  function renderStudentInfo() {
    const { students } = project
    let department = isHeb ? project.hebDepartment : project.department

    if(project.hebDepartment === 'הנדסת תעשייה וניהול') {
      department = isHeb ? 'בית-הספר להנדסת תעשייה וניהול' : 'School of Industrial Engineering & Management'
    }
    
    const textStyle = {
      ...align,
      margin: 0, 
      fontSize: '18px', 
      color: '#4D4D4D',
      padding: 0,
      marign: 0,
      lineHeight: 1,
      fontWeight: 'lighter',
      letterSpacing: '0.36px',
      marginBottom: '10px'
    }

    return (
      <div style={{marginBottom: isMobile ? '50px' : 0}}>
        <div className='clear' />
        <p dir={dir} style={{ ...align, margin: 0, fontSize: '18px', color: '#4D4D4D', fontWeight: '400'}}>{department}</p>
        {        
        students.map((student, index) => {
          let { portfolio } = student
          portfolio = portfolio.trim()
          let isGit = false

          if (portfolio.includes('git')) {
            isGit = true
          }

          return (
            <div style={{marginBottom: '5px'}} key={`student${index}`}>
              <p dir={dir} style={textStyle}>{student.email}</p>
              {renderPortfolio(student)}
              {!isGit && <div className='clear' />}
              {renderSocial(student)}
              <div className='clear' />
            </div>
          )
        })
      }
      </div>
    )
  }

  function renderInfo() {
    const projectName = isHeb ? project.hebProjectName : project.projectName
    const names = getNames()
    const float = isHeb ? 'right' : 'left'
    const containerStyle = isMobile ? 
      {paddingLeft: isHeb ? 0 : '0', paddingRight: isHeb ? '0' : 0, width: '80%', height: 'max-content' } 
      : 
      { width: '50%', height: '50%', float: float }
    const textStyle = {
      margin: 0,
      fontSize: isMobile ? '37px' : projectName.length > 20 ? '38px' : '42px',
      padding: 0,
      fontWeight: 'bold',
      letterSpacing: '0.36px',
      lineHeight: 1,
      width: '80%',
      float: float
    }

    return (
      <div className={isMobile ? 'container' : ''} style={containerStyle}>
        <p dir={dir} style={{ ...align, ...textStyle, color: color}}>{projectName}</p>
        <div className='clear' />
        {names.map((name, i) => 
          <p dir={dir} style={{ ...align, ...textStyle, color: '#4D4D4D'}} key={`name${i}`}>{name}</p>)
        }
        <div className='clear' />
        <div style={{marginTop: isMobile ? '40px' : '55px'}}>
          {renderStudentInfo()}
        </div>
      </div>
    )
  }

  function renderDescription() {
    const description = isHeb ? project.hebDescription : project.description
    const advisors = isHeb ? project.hebAdvisors : project.advisors
    const float = isHeb ? 'left' : 'right'
    const containerStyle = isMobile ?
      { marginTop: '0px', paddingLeft: isHeb ? 0 : '0', paddingRight: isHeb ? '0' : 0, width: '80%', height: 'max-content' }
      :
      { width: '50%', height: '50%', float: float }

    const advisorsPreText = advisors.length > 1 ? 
    (isHeb ? 'מנחים: ' : 'Supervisors: ') 
      : 
    (isHeb ? 'מנחה: ' : 'Supervisor: ') 
    let advisorsText = ''
    advisors.map((advisor, i) => {
      if(i !== 0) {
        advisorsText += ', '
      }
      advisorsText += advisor

      return null
    })

    return (
      <div className={isMobile ? 'container' : ''} style={containerStyle}>
        <p dir={dir} style={{...align, fontWeight: 'lighter'}}>{description}</p>
        {advisors.length> 0 && <p dir={dir} style={{...align, fontWeight: 'lighter'}}>{advisorsPreText}{advisorsText}</p>}
      </div>
    )
  }

  function renderVideos(videos) {
    const width = isMobile ? '100%' : '100%'
    const height = isMobile ? '275px' : '550px'

    return (
      <div style={{marginTop: '35px'}}>
        {videos.map((video, index) => {
          if(!video.includes('https://')) {
            video = 'https://' + video
          }
          return <iframe key={`video${index}`} title={`video${index}`} src={video} width={width} height={height}></iframe>
        }
        )}
      </div>
    )
  }

  function handleSelectPhoto(photo) {
    mounted.current && setCurrentPhoto(photo)
    mounted.current && setShowImages(true)
  }

  // function renderPhoto(photo, style) {
  //   if(photo.length === 0) {
  //     return null
  //   }

  //   return (
  //     <img 
  //       className='imagePicker' 
  //       loader={
  //         <div className='imagePicker' style={style}>
  //           <ClipLoader className='picLoader' size={45} color={"#123abc"} loading={true} />
  //         </div>
  //       }
  //       key={`photo${photo}${Math.random()}`} 
  //       onClick={() => mounted.current && handleSelectPhoto(photo)}
  //       style={style}
  //       src={`https://drive.google.com/uc?export=view&id=${photo}`} 
  //     />
  //   )
  // }

  function renderPhoto(photo, style) {
    let { projectName, department, hebProjectName } = project
    let src
    projectName = projectName.length === 0 ? hebProjectName : projectName
    if(projectName.length === 0) {
      projectName = project.students[0].hebName
    }
    projectName = projectName.replace(/[?!:|*><,"“'`”‘’/\\]/g, '')
    projectName = projectName.replace(/[\u0591-\u05C7]/g, '')
    projectName = projectName.trim()

    try {
      src = require(`../../Images/${department}/${projectName}/${photo}.jpg`)
    }
    catch (err) {
      projectName = hebProjectName
      projectName = projectName.replace(/[?!:|*><,"'“”‘’/\\]/g, '')
      projectName = projectName.replace(/[\u0591-\u05C7]/g, '')
      projectName = projectName.trim()

      try {
        src = require(`../../Images/${department}/${projectName}/${photo}.jpg`)
      }
      catch {
        return null
      }
    }

    return (
      <div key={`div${Math.random()}`} style={isMobile ? {} : {width: '100%'}}>
        <Img
          className='imagePicker'
          loader={
            <div style={{ width: '100%' }}>
              <div className='picLoader'>
                <SquareLoader size={22.5} color={color} loading={true} />
              </div>
            </div>
          }
          key={`photo${photo}${Math.random()}`}
          onClick={() => mounted.current && handleSelectPhoto(photo)}
          style={style}
          src={src}
          alt='projectPicture'
        />
      </div>
    )
  }

  function renderMainPhoto() {
    const { video, department } = project
    let tempPhotos = [1, 2, 3, 4, 5, 6, 7, 8]

    let style = {
      width: '70%',
      height: 'auto',
      marginTop: '25px'
    }

    if(department === 'Fashion Design' || department === 'Jewelry Design') {
      style.width = '50%'
    }

    if(isMobile) {
      style.width = '80%'
      style.marginTop = '25px'
    }

    return(
      <div>
        {video.length !== 0 && renderVideos(video)}
        {tempPhotos.map(photo => renderPhoto(photo, style))}
      </div>
    )
  }

  function renderImageGallery() {
    return (
      <div>
        {renderButton(() => mounted.current && setShowImages(false))}
        <ImageCarousel
          project={project}
          currentPhoto={currentPhoto}
          color={color}
        />
      </div>
    )
  }

  function renderProject() {
    return (
      <div>
        {renderButton(onClose)}
        <div className='container'>
          <div style={{ marginBottom: '25px', minHeight: '300px' }}>
            {project && renderInfo()}
            {project && renderDescription()}
          </div>
          <div className='clear' />
          <div style={{ width: '100%'}}>
            {project && renderMainPhoto()}
            <div className='clear' />
          </div>
          <div className='clear'/>
          <hr style={{ marginTop: '88px' }} />
        </div>
        <RecommendedList project={project} setProject={setProject} />
        <Footer />
      </div>
    )
  }

  return (
    <div style={{height: '100%', width: '100%'}} className='projectPage'>
      <div style={{ marginTop: '50px', backgroundColor: color, width: '100%', height: '15px' }} />
      {showImages ? renderImageGallery() : renderProject()}
    </div>
  )
}

export default ProjectPage