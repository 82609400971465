import { Speeches } from '../Speeches'
import Footer from '../Utilities/Footer'
import { animateScroll } from 'react-scroll'
import React, { useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import useKeyPress from '../Utilities/useKeyPress'
import { colorArray, englishDep, hebrewDep } from '../consts'
import { withRouter } from 'react-router'

const SpeechPage = props => {
  const { onClose, speech, isHeb } = props

  const align = isHeb ? { textAlign: 'right' } : { textAlign: 'left' }
  const dir = isHeb ? 'rtl' : 'ltr'
  const escapePress = useKeyPress('Escape')
  const mounted = useRef(false)
  const depIndex = englishDep.findIndex(value => value === speech)
  const depName = isHeb ? hebrewDep[depIndex] : englishDep[depIndex]

  if(depIndex < 0) {
    props.history.push('/')
  }


  useEffect(() => {
    if (escapePress) {
      onClose(true)
    }
  }, [escapePress, onClose])

  useEffect(() => {
    mounted.current = true
    animateScroll.scrollTo(0, { smooth: false, duration: 0.1, isDynamic: true })
    return () => mounted.current = false
  }, [])

  function renderGradScroll() {
    return (
      <div style={{ position: 'relative', top: '44px' }}>
        <svg height='15px' width='100%'>
          <defs>
            <linearGradient id='grad1' x1='0%' y1='0%' x2='100%' y2='0%'>
              {colorArray.map((color, index) => <stop key={`stop${color}`} offset={`${5 * index}%`} stopColor={color} stopOpacity='1' />)}
            </linearGradient>
          </defs>
          <rect height='15px' width='100%' fill='url(#grad1)' />
        </svg>
      </div>
    )
  }

  function renderButton(onClose) { 
    return (
      <div
        style={{
          width: 'calc(100% - 17px)',
          height: '25px',
          marginBottom: isMobile ? '2px' : '33px',
          marginTop: isMobile ? '17px' : '60px',
          marginLeft: isMobile ? '17px' : '34px',
        }}
      >
        <img
          onClick={() => onClose(true)}
          style={{ width: '23px', height: '23px', cursor: 'pointer' }}
          src={require('../../Images/x.svg')}
          alt="x"
        />
      </div>
    )
  }

  function renderInfo() {
    let title = isHeb ? 'דבר ראש המחלקה' : 'Head of Department Speech'
    let tempDepName = isHeb ? hebrewDep[depIndex] : englishDep[depIndex]

    const float = isHeb ? 'right' : 'left'
    const departmentStyle = {
      ...align,
      margin: 0,
      fontSize: isMobile ? '37px' : '42px',
      padding: 0,
      color: '#626262',
      fontWeight: 'bold',
      letterSpacing: '0.36px',
      lineHeight: 1
    }

    if(hebrewDep[depIndex] === 'הנדסת תעשייה וניהול') {
      title = isHeb ? 'דבר הדיקן' : "Dean's Speech"
      tempDepName = isHeb ? 'בית-הספר להנדסת תעשייה וניהול' : 'School of Industrial Engineering & Management'
    }

    const containerStyle = isMobile ? 
    {
      width: '80%',
      marginLeft : isHeb ? '10%' : 0,
      marginRight: isHeb ? 0 : '10%'
    } 
    : 
    {
      paddingTop: '24px', 
      width: '50%', 
      height: '50%', 
      float: float
    }

    return (
      <div style={containerStyle}>
        <p dir={dir} style={{ ...align, ...departmentStyle }}>{tempDepName}</p>
        <p dir={dir} style={{ ...align, ...departmentStyle }}>{title}</p>
        <p dir={dir} style={{ ...align, marginTop: isMobile ? '33px' : 0, fontSize: '18px', fontWeight: 400, color: '#626262'}}>{Speeches[depName]?.head}</p>
      </div>
    )
  }

  function renderDescription() {
    const float = isHeb ? 'left' : 'right'
    const style = {
      ...align,
      fontWeight: 'lighter',
      letterSpacing: '0.36px',
      fontSize: '18px'
    }

    const containerStyle = isMobile ?
    {
      width: '80%',
      marginLeft: isHeb ? '10%' : 0,
      marginRight: isHeb ? 0 : '10%'
    }
    :
    {width: '50%', height: '50%', float: float}

    return (
      <div style={containerStyle}>
        <article dir={dir} style={style}>
          {Speeches[hebrewDep[depIndex]]?.text.map((p, index) => {
            return (
              <div key= {`paragraph${index}`}>
                {index !== 0 ? 
                <p dir={dir} style={style}>{p}</p> 
                :
                <p dir={dir} style={style}><br />{p}</p>
                }
              </div>
            )
          })}
        </article>
      </div>
    )
  }

  function renderSpeech() {
    return (
      <div style={{marginTop: '55px'}}>
        {renderButton(onClose)}
        <div className='container'>
          <div style={{minHeight: '500px'}}>
            {depName && renderInfo()}
            {depName && renderDescription()}
          </div>
          <div className='clear'/>
          <hr style={{ marginTop: '90px' }} />
        </div>
        <Footer />
      </div>
    )
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className='speechPage'>
      {renderGradScroll()}
      {renderSpeech()}
    </div>
  )
}

export default withRouter(SpeechPage)