import { useSelector } from 'react-redux'
import { animateScroll } from 'react-scroll'
import React, { useState, useEffect, useRef } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { isMobile } from 'react-device-detect'

const ImageCarousel = props => {

  const { project, currentPhoto, color } = props
  const [curr, setCurr] = useState(currentPhoto - 1)
  let { department, projectName, hebProjectName } = project
  const images = [1, 2, 3, 4, 5, 6, 7, 8]
  const { isHeb } = useSelector(state => state.project)
  const align = isHeb ? { textAlign: 'right' } : { textAlign: 'left' }
  const dir = isHeb ? 'rtl' : 'ltr'
 
  const mounted = useRef(false)
  let imageNumber = 0

  if (projectName.length === 0) {
    projectName = project.students[0].hebName
  }
  
  projectName = projectName.replace(/[?!:|*><,"“”‘’/\\]/g, '')
  projectName = projectName.replace(/[\u0591-\u05C7]/g, '')
  projectName = projectName.trim()

  hebProjectName = hebProjectName.replace(/[?!:|*><,"“”‘’/\\]/g, '')
  hebProjectName = hebProjectName.replace(/[\u0591-\u05C7]/g, '')
  hebProjectName = hebProjectName.trim()
  
  useEffect(() => {
    mounted.current = true
    animateScroll.scrollTo(0, { smooth: false, duration: 0.1, isDynamic: true })

    return () => mounted.current = false
  }, [])

  function renderButton() {
    const style = {
      width: '15px',
      height: '15px',
      backgroundColor: color,
      float: 'left',
      marginLeft: '10px'
    }

    return (
      <div style={style} />
    )
  }

  function renderSelectedButton(image) {
    // const style = {
    //     fill: color,
    //     mixBlendMode: 'screen'
    // }

    return (
        <svg style={{marginLeft: '10px', position: 'relative', top: '-4.5px'}} width='65px' height='14px'>
          <image
            preserveAspectRatio='xMidYMid slice'
            width='65px'
            height='16px'
            href={image}
          />
        {/* <rect
          style={style}
          width='65px'
          y='-4'
          height='15px'
        /> */}
        </svg>
    )
  }

  function getStudents() {
    const { students } = project
    let names = ''

    students.map((student, index) => {
      if(index > 0) {
        names += ', '
      }

      names += isHeb ? student.hebName : student.name
      return null
    })

    return names
  }

  function renderInfo() {
    let projectName = isHeb ? project.hebProjectName : project.projectName
    const students = getStudents()

    if(projectName.length > 20) {
      projectName = projectName.slice(0, 20) + '...'
    }

    const style = {
      position: 'relative',
      top: '-51px',
      left: '5%',
      float: 'left',
      marginLeft: '5px',
      fontSize: '18px',
      fontWeight: 400,
      ...align
    }

    const hebStyle = {
      position: 'relative',
      top: '-51px',
      right: '87px',
      float: 'right',
      marginLeft: '5px',
      fontSize: '18px',
      fontWeight: 400,
      ...align
    }

    const selectedStyle = isHeb ? hebStyle : style

    return (
      <div>
        <label dir={dir} style={{...selectedStyle, color: color}}>{projectName}</label>
        <label dir={dir} style={{...selectedStyle, color: '#4D4D4D'}}>{students}</label>
        <div className='clear' />
      </div>
    ) 
  }

  function renderButtons() {
    // const style = {
    //   position: 'relative',
    //   width: '20%',
    //   height: '20px',
    //   top: '-28px',
    //   left: '80%'
    // }

    // const hebStyle = {
    //   position: 'relative',
    //   width: '20%',
    //   height: '20px',
    //   top: '-28px',
    //   left: '5%'
    // }

    const mobileStyle = {
      position: 'relative',
      width: 'max-content',
      height: '20px',
      top: '-28px',
      left: '50%',
      transform: 'translate(-50%)'
    }

    return (
      <div style={mobileStyle}>
        {images.map((image, index) => {
          let src
          try {
            src = require(`../../Images/${department}/${projectName}/${image}.jpg`)
            ++imageNumber
          }
          catch (err) {
            try {
              src = require(`../../Images/${department}/${hebProjectName}/${image}.jpg`)
              ++imageNumber
            }
            catch {
              return null
            }
          }

          const style = curr === index ? 
          { height: '15px', position: 'relative', top: '1px' } 
          : 
          { height: '15px' }

          return (
            <button style={style} className='galleryButton' key={`image${image}${Math.random()}`} onClick={() => setCurr(index)}>
              {curr === index ? renderSelectedButton(src) : renderButton()}
            </button>
          )
        }
        )}
      </div>
    )
  }

  function handleLeft() {
    if(curr === 0) {
      setCurr(imageNumber  - 1)
    }
    else {
      setCurr(curr - 1)
    }
  }

  function handleRight() {
    if(curr === imageNumber - 1) {
      setCurr(0)
    }
    else {
      setCurr(curr + 1)
    }
  }

  function renderSideButtons() {
    const style = {
      fontSize: '2em',
      position: 'relative',
      top: 'calc(50vh - 165px)',
    }

    return (
      <div>
        <button
          onClick={handleLeft}
          className='invisButton'
          style={{ ...style, float: 'left', paddingLeft: '40px' }}
        >
          <svg width='1em' height='1.3em' viewBox='0 0 16 16' className='bi bi-chevron-left' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z' />
          </svg>
        </button>
        <button onClick={handleRight} className='invisButton' style={{ ...style, float: 'right', paddingRight: '40px' }}>
          <svg width='1em' height='1em' viewBox='0 0 16 16' className='bi bi-chevron-right' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z' />
          </svg>
        </button>
      </div>
    )
  }

  function handleSwipeChange(index) {
    if(index === imageNumber) {
      setCurr(0)
    }
    else if(index > imageNumber) {
      setCurr(imageNumber - 1)
    }
    else setCurr(index)
  }

  function renderGallery(style) {
    const imageStyle = isMobile ? { height: 'auto', width: '90%' } : { height: '560px', width: 'auto' }

    return (
      <div className='imageCarousel'>
        {renderButtons()}
        {!isMobile && renderInfo()}
        {!isMobile && renderSideButtons()}
        <div style={style}>
          <Carousel
            swipeable={true}
            emulateTouch={isMobile ? true : false}
            selectedItem={curr}
            showArrows={false}
            showThumbs={false}
            infiniteLoop={true}
            dynamicHeight={false}
            showStatus={false}
            showIndicators={false}
            onChange={isMobile ? handleSwipeChange : undefined}
          >
            {
              images.map((image, index) => {
                let src
                try {
                  src = require(`../../Images/${department}/${projectName}/${image}.jpg`)
                }
                catch (err) {
                  try {
                    src = require(`../../Images/${department}/${hebProjectName}/${image}.jpg`)
                  }
                  catch {
                    return null
                  }
                }

                return (
                  <div key={`imagecarousel${index}`}>
                    <img alt='project pic' style={imageStyle} src={src} />
                  </div>
                )
              })
            }
          </Carousel>
        </div>
      </div>
    )
  }

  const style = {
    height: department === 'Fashion Design' ? '560px' : '560px',
    width: department === 'Fashion Design' ? '921px' : '921px',
    position: 'relative',
    left: '50%',
    top: '-67px',
    transform: 'translate(-50%)'
  }

  const mobileStyle = {
    height: department === 'Fashion Design' ? '440px' : '200px',
    width: '295px',
    position: 'relative',
    left: '50%',
    transform: 'translate(-50%)'
  }

  return renderGallery(isMobile ? mobileStyle : style)
}
export default ImageCarousel