import useKeyPress from './useKeyPress'
import { animateScroll } from 'react-scroll'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ProjectActions from '../../Actions/ProjectActions'
import { tagsArray, colors, englishTags } from '../consts'

const TopicOverlay = props => {
  const dispatch = useDispatch()
  const { onClose, shown, projectShown, changedSort } = props
  const { isHeb } = useSelector(state => state.project)
  const dir = isHeb ? 'rtl' : 'ltr'
  const escapePress = useKeyPress('Escape')
  const [hasShown, setHasShown] = useState(false)
  let isVertical = true

  useEffect(() => {
    if (escapePress) {
      onClose()
    }
  }, [escapePress, onClose])

  useEffect(() => {
    !hasShown && shown && setHasShown(true)
  }, [hasShown, shown])


  function handleHover(color) {
    document.getElementsByClassName('topicOverlay')[0].style['background-color'] = color
  }

  function handleTopicSelect(topic) {
    const { setSort } = ProjectActions
    const index = tagsArray.findIndex(value => topic === value)
    dispatch(setSort({ type: 'topic', index: index }))
    dispatch(ProjectActions.setScroll(0))
    !projectShown && animateScroll.scrollTo(0, { smooth: false, duration: 0.1, isDynamic: true })
    onClose()
    changedSort()
  }

  function eachTopic(topic, index) {
    isVertical = !isVertical

    const style = {
      fontSize: '33px',
      fontWeight: 'bold',
      color: '#FFFFFF',
      float: isHeb ? 'right' : 'left',
      width: 'max-content',
      cursor: 'pointer'
    }

    const boxStyle = {
      width: isVertical ? '15px' : '40px',
      height: isVertical ? '40px' : '15px',
      backgroundColor: '#FFFFFF',
      float: isHeb ? 'right' : 'left',
      position: 'relative',
      top: isVertical ? '6px' : '18px',
      marginLeft: '40px',
      marginRight: '40px'
    }

    let findIndex

    if(isHeb) {
      findIndex = tagsArray.findIndex(value => value === topic)
    }
    else {
      findIndex = englishTags.findIndex(value => value === topic)
    }

    return (
      <div onClick={() => handleTopicSelect(tagsArray[findIndex])} key={`topic${topic}`} dir={dir} style={{float: isHeb ? 'right' : 'left'}}>
        <p 
          onMouseEnter={() => handleHover(colors[tagsArray[findIndex]])} 
          onMouseLeave={() => handleHover('#4D4D4D')} 
          dir={dir} 
          style={style}>
          {topic}
        </p>
        {(index % 5 !== 4) && <div style={boxStyle} />}
      </div>
    )
  }

  function eachLine(line, index) {
    return (
      <div key={`line${index}`} style={{width: '100%'}}>
        <div className='clear' />
        <div className='topicLine'>
          {line.map(eachTopic)}
        </div>
        <div className='clear' />
      </div>
    )
  }
  
  function renderTopics() {
    const tempArray = isHeb ? [...tagsArray] : [...englishTags]
    const newArray = new Array(Math.ceil(tempArray.length / 5)).fill().map(() => tempArray.splice(0, 5))

    return (
      <div className='topicContainer' style={{minWidth: '150px', minHeight: '150px'}}>
        <div className='clear' />
        {newArray.map(eachLine)}
      </div>
    )
  }

  const style = {
    width: '100vw',
    height: projectShown ? '100vh' : 'calc(100vh - 50px)',
    marginTop: projectShown ? 0 : "50px"
  }

  return (
    <div style={style} className={`topicOverlay ${shown ? 'slide-bottom' : hasShown ? 'slide-top' : ''}`}>
      <div style={{height: '100%', width: '100%'}}>
        {renderTopics()}
        <div className='clear' />
        <button className='overlayExit' onClick={onClose}>
          <img
            src={require('../../Images/x white.svg')}
            alt="x"
          />
        </button>
      </div>
    </div>
  )
}

export default TopicOverlay