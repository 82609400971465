import useKeyPress from './useKeyPress'
import { animateScroll } from 'react-scroll'
import { hebrewDep, englishDep } from '../consts'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ProjectActions from '../../Actions/ProjectActions'

const DepartmentsOverlay = props => {
  const dispatch = useDispatch()
  const { onClose, shown, projectShown, changedSort } = props
  const { isHeb } = useSelector(state => state.project)
  const dir = isHeb ? 'rtl' : 'ltr'
  const escapePress = useKeyPress('Escape')
  const [hasShown, setHasShown] = useState(false)
  const departments = isHeb ? hebrewDep : englishDep
  const [image, setImage] = useState(false)

  useEffect(() => {
    if (escapePress) {
      onClose()
    }
  }, [escapePress, onClose])

  useEffect(() => {
    !hasShown && shown && setHasShown(true)
  }, [hasShown, shown])

  function handleDepSelect(dep) {
    const { setSort } = ProjectActions
    const index = departments.findIndex(value => dep === value)
    dispatch(ProjectActions.setScroll(0))
    dispatch(setSort({ type: 'dep', index: index }))
    !projectShown && animateScroll.scrollTo(0, { smooth: false, duration: 0.1, isDynamic: true })
    onClose()
    changedSort()
  }

  function handleHoverEnter(dep) {
    const index = departments.findIndex(value => dep === value)
    let tempDep = englishDep[index].replace(/ /g, '')
    setImage(true)
    document.getElementsByClassName('depOverlayImage')[0].style.backgroundImage = `url(${require(`../../Images/Departments/${tempDep}.jpg`)})`
  }

  function handleHoverLeave() {
    setImage(false)
  }

  function eachDep(dep, index, length) {
    let tempDep = dep

    if (dep === 'הנדסת תעשייה וניהול' || dep === 'Industrial Engineering & Management') {
      tempDep = isHeb ? 'בית-הספר להנדסת תעשייה וניהול' : 'School of Industrial Engineering & Management'
    }

    const style = {
      fontSize: isHeb ? '33px' : 'calc(33px * 0.9)',
      fontWeight: 'bold',
      color: '#FFFFFF',
      letterSpacing: 0,
      lineHeight: 0.2,
      float: isHeb ? 'right' : 'left',
      width: 'max-content',
      cursor: 'pointer'
    }

    const boxStyle = {
      width: '15px',
      height: '15px',
      backgroundColor: '#FFFFFF',
      float: isHeb ? 'right' : 'left',
      position: 'relative',
      bottom: '4px',
      marginLeft: '25px',
      marginRight: '25px'
    }

    return (
      <div onClick={() => handleDepSelect(dep)} key={`dep${dep}`} dir={dir} style={{ float: isHeb ? 'right' : 'left' }}>
        <p
          onMouseEnter={() => handleHoverEnter(dep)}
          onMouseLeave={() => handleHoverLeave()}
          dir={dir}
          style={style}>
          {tempDep}
        </p>
        {(index !== length - 1) && <div style={boxStyle} />}
      </div>
    )
  }

  function eachLine(line, index) {
    return (
      <div key={`line${index}`} style={{ width: '100%' }}>
        <div className='clear' />
        <div className='topicLine'>
          {line.map((dep, index) => eachDep(dep, index, line.length))}
        </div>
        <div className='clear' />
      </div>
    )
  }

  function renderDeps() {
    const tempDep = []
    tempDep.push([departments[0], departments[1], departments[2]])
    tempDep.push([departments[3], departments[4], departments[5]])
    tempDep.push([departments[6], departments[7]])
    tempDep.push([departments[8], departments[9]])
    tempDep.push([departments[10], departments[11]])
    tempDep.push([departments[12], departments[13], departments[14]])

    return (
      <div className='topicContainer' style={{ minWidth: '150px', minHeight: '150px' }}>
        {tempDep.map(eachLine)}
        <div className='clear' />
      </div>
    )
  }

  const style = {
    width: '100vw',
    height: projectShown ? '100vh' : 'calc(100vh - 50px)',
    marginTop: projectShown ? 0 : '50px',
    backgroundImage: `url(${require(`../../Images/Departments/default.png`)})`
  }

  return (
    <div style={style} className={`depOverlay ${shown ? 'slide-bottom' : hasShown ? 'slide-top' : ''}`}>
      <div style={{ height: '100%', width: '100%' }}>
        <div 
          className={`depOverlayImage ${image ? 'fadePicIn' : 'fadePicOut'}`}
          style={{position: 'absolute', zIndex: -1, height: '100%', width: '100%' }}
        />
        {renderDeps()}
        <button className='depOverlayExit' onClick={onClose}>
          <img
            src={require('../../Images/x white.svg')}
            alt="x"
          />
        </button>
        <div className='clear' />
      </div>
    </div>
  )
}

export default DepartmentsOverlay