import useKeyPress from './useKeyPress'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ProjectActions from '../../Actions/ProjectActions'
import { isMobile } from 'react-device-detect'
import { withRouter } from 'react-router'

const SearchOverlay = props => {
  const dispatch = useDispatch()
  const escapePress = useKeyPress('Escape')
  const { onClose, shown, projectShown, changedSort } = props
  const [hasShown, setHasShown] = useState(false)
  const [input, setInput] = useState('')
  const [suggest, setSuggest] = useState([])
  const { isHeb, projects } = useSelector(state => state.project)
  const dir = isHeb ? 'rtl' : 'ltr'
  const align = isHeb ? { textAlign: 'right' } : { textAlign: 'left' }
  
  useEffect(() => {
    if (escapePress) {
      onClose()
    }
  }, [escapePress, onClose])

  useEffect(() => {
    !hasShown && shown && setHasShown(true)
  }, [hasShown, shown])

  function handleFilter(e, name) {
    const { filterProjects, setScroll } = ProjectActions
    const filterName = name ? name.toLocaleUpperCase() : input.toLocaleUpperCase()
    const tempFiltered = projects.filter(project => {
      const { students } = project
      if(filterName.length === 0) {
        return true
      }
      
      for (let i = 0; i < students.length; ++i) {
        let { name, hebName } = project.students[i]
        name = name.toLocaleUpperCase()
        hebName = hebName.toLocaleUpperCase()

        if (name.startsWith(filterName) || hebName.startsWith(filterName)) {
          return true
        }
      }

      return false
    })

    e.preventDefault()
    if(tempFiltered.length === 0) {
      return
    }

    if(tempFiltered.length === 1) {
      let name = tempFiltered[0].projectName.length > 0 ? tempFiltered[0].projectName : tempFiltered[0].hebProjectName
      name = name.replace(/[?!:|*><,"%#“'`”‘’/\\]/g, '')
      name = name.replace(/[\u0591-\u05C7]/g, '')
      name = name.trim()

      dispatch(filterProjects(projects))
      dispatch(setScroll(0))
      setInput('')
      onClose()
      changedSort()
      props.history.push(`/project/${name}`)
    }
    else {
      dispatch(filterProjects(tempFiltered))
      dispatch(setScroll(0))
      setInput('')
      onClose()
      changedSort()
    }
  
  }

  function handleButtonClick(e, student) {
    e.preventDefault()
    handleFilter(e, student.name)
  }

  function handleChange(e) {
    const filterName = e.target.value.toLocaleUpperCase()
    const tempFiltered = projects.filter(project => {
      const { students } = project

      for(let i = 0; i < students.length; ++i) {
        let { name, hebName } = project.students[i]
        name = name.toLocaleUpperCase()
        hebName = hebName.toLocaleUpperCase()

        if(name.startsWith(filterName) || hebName.startsWith(filterName)) {
          return true
        }
      }

      return false
    })

    setSuggest(tempFiltered)
    setInput(e.target.value)
  }

  function eachSuggest(value, index) {
    if(index > 6 || input.length === 0) {
      return null
    }

    const { students } = value
    
    const student = students.find(tempStudent => { 
      let tempName = tempStudent.name.toLocaleUpperCase()
      let hebTempName = tempStudent.hebName.toLocaleUpperCase()
      let tempInput = input.toLocaleUpperCase()

      return tempName.startsWith(tempInput) || hebTempName.startsWith(tempInput)
    })

    if(student === undefined) {
      return null
    }

    const textStyle = { float: isHeb ? 'right' : 'left', width: 'max-content', fontSize: '18px', fontWeight: 'lighter', cursor: 'pointer' }

    const margin = isHeb ?
      { marginLeft: '5px' }
      :
      { marginRight: '5px' }
    
    let department

    if(isMobile && ((isHeb && value.hebDepartment.length > 20) || (!isHeb && value.department.length > 20))) {
      department = isHeb ? value.hebDepartment.slice(0, 20) + '...' : value.department.slice(0, 20) + '...'
    }
    else department = isHeb ? value.hebDepartment : value.department

    return (
      <div style={{height: '15px', marginBottom: '12px'}} key={`suggest${index}`} dir={dir}>
        <button style={{width: '100%'}} className='invisButton' onClick={e => handleButtonClick(e, student)}>
          <label dir={dir} onClick={e => handleButtonClick(e, student)} style={{ ...align, ...margin, ...textStyle, color: '#626262'}}>
            {isHeb ? student.hebName : student.name}
          </label>
          <label dir={dir} onClick={e => handleButtonClick(e, student)} style={{ ...align, ...textStyle, color: '#C1C1C1'}}>
            {department}
          </label>
        </button>
      </div>
    )
  }

  const style = {
    width: '100vw',
    marginTop: projectShown ? 0 : "50px"
  }

  return (
    <div style={style} className={`searchOverlay ${shown ? 'slide-bottom' : hasShown ? 'slide-top' : ''}`}>
      <div style={{ height: '100%', width: '100%' }}>
        <div className='container'>
          <form onSubmit={handleFilter}>
            <div className='searchbar'>
              <input
                value={input}
                onChange={handleChange}
                name="student"
                dir={dir}
                className='searchInput'
                placeholder={isHeb ? 'הקלד שם סטודנט/ית' : `Enter student's name`}
                style={{ ...align, width: isMobile ? '95%' : '98%'}}
              />
              <input type="submit" style={{display: 'none'}} />
              <button 
                style={{float: isHeb ? 'left' : 'right'}} 
                className='searchBarButton' 
                onClick={handleFilter}
              >
                <img
                  src={require('../../Images/searchbarIcon.svg')}
                  alt="search"
                />
              </button>
            </div>
            <div style={{float: isHeb ? 'right' : 'left', marginTop: '12px'}}>
              {suggest.map(eachSuggest)}
            </div>
            {/* <div className='clear' /> */}
          </form>
        </div>
      </div>
      {!isMobile && 
        <button className='searchExitButton' onClick={onClose}>
          <img
            src={require('../../Images/x.svg')}
            alt="x"
          />
        </button>
      }
    </div>
  )
}

export default withRouter(SearchOverlay)